import React from "react";

import { Table as AntdTable, TableProps } from "components/antd/Table";
import { defaultTableScrollHeight } from "constants/defaultTableScrollHeight";

type Props<T> = {
  // If you are using a table inside another table, set the "shouldDisableMaxWidthScroll" prop to true to avoid the table growing too large.
  shouldDisableMaxWidthScroll?: boolean;
} & Omit<TableProps<T>, "loading"> & {
    loading?: boolean;
  };

// Set scroll.y property to make table with different height than default
export const Table = <T extends Record<string, unknown>>({
  loading = false,
  ...props
}: Props<T>) => (
  <AntdTable
    bordered
    loading={{
      spinning: loading,
      size: "large" as const,
    }}
    {...props}
    scroll={{
      // Allowing only scroll property to be specified while giving the other a default value
      x: props.scroll?.x ?? (props.shouldDisableMaxWidthScroll ? 0 : "max-content"),
      y: props.scroll?.y ?? defaultTableScrollHeight,
    }}
  />
);
