import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";

import { TableContent } from "components/AnalyticsTable/TableContent";
import { TableStyleModifier } from "components/AnalyticsTable/TableStyleModifier";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { FaveYellAnalyticsByCorporationOutput } from "types/graphql";

const CenteredTitle = styled.span`
  text-align: center;
`;

const NoImage = styled.div`
  width: 64px;
  height: 64px;
`;

const FaveYellStaffImage = styled.img`
  width: 64px;
  height: 64px;
  background: white;
`;

const RankBadgeImage = styled.img`
  width: 50px;
  background: white;
`;

const NumberValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: right;
  width: 100%;
`;

const ColumnTitle = styled.span`
  font-weight: 700;
`;

const StyledTableContent = styled(TableContent)`
  text-align: right;
`;

const formatter = new Intl.NumberFormat("ja");

const columns: ColumnsType<FaveYellAnalyticsByCorporationOutput["items"][number]> = [
  {
    title: <CenteredTitle>順位</CenteredTitle>,
    dataIndex: "faveYellSalesRank",
    width: 100,
    align: "center",
  },
  {
    title: <ColumnTitle>店舗名</ColumnTitle>,
    dataIndex: "shopName",
  },
  {
    title: <ColumnTitle>スタッフ画像</ColumnTitle>,
    dataIndex: "staffImageUrl",
    width: 120,
    align: "center",
    render: (value: string, { staffImageUrl }) =>
      staffImageUrl ? <FaveYellStaffImage src={staffImageUrl} /> : <NoImage />,
  },
  {
    title: <ColumnTitle>スタッフ名</ColumnTitle>,
    dataIndex: "staffName",
  },
  {
    title: <ColumnTitle>推しエール人数</ColumnTitle>,
    dataIndex: "faveYellCustomerCount",
    width: 140,
    align: "center",
    render: (value: number, { faveYellCustomerCount }) => (
      <NumberValue>{faveYellCustomerCount}人</NumberValue>
    ),
  },
  {
    title: <ColumnTitle>推しエール件数</ColumnTitle>,
    dataIndex: "faveYellCount",
    width: 140,
    align: "center",
    render: (value: number, { faveYellCount }) => <NumberValue>{faveYellCount}件</NumberValue>,
  },
  {
    title: <ColumnTitle>推しエール合計額</ColumnTitle>,
    dataIndex: "totalTaxIncludedSales",
    width: 168,
    align: "center",
    render: (value: number) => (
      <StyledTableContent content={`¥${formatter.format(value)}`} textAlign="right" isHighlight />
    ),
  },
];

type Props = {
  items: FaveYellAnalyticsByCorporationOutput["items"];
};

export const SummaryPerStaffTable = memo<Props>(({ items }) => {
  const [pagination, setPagination] = usePagination();

  const handleChangePagination = useCallback(
    ({ position: _, ...pagination }: TablePaginationConfig) => setPagination(pagination),
    [setPagination],
  );

  return (
    <TableStyleModifier>
      <Table
        columns={columns}
        dataSource={items}
        bordered
        pagination={pagination}
        onChange={handleChangePagination}
        scroll={{ x: 1136 }}
      />
    </TableStyleModifier>
  );
});
