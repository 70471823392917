import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LatestOrderElapsedTimeAlertThresholdMinutesField = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label="最終注文時刻からの経過時間"
    name="latestOrderElapsedTimeAlertThresholdMinutes"
    {...props}
    rules={[
      {
        validator: async (_, latestOrderElapsedTimeAlertThresholdMinutes: number) => {
          if (typeof latestOrderElapsedTimeAlertThresholdMinutes !== "number") {
            throw new Error("最終注文時刻からの経過時間を入力してください");
          }

          if (latestOrderElapsedTimeAlertThresholdMinutes < 1) {
            throw new Error("1分以上の数値を入力してください");
          }
        },
      },
    ]}
  >
    <InputNumber
      formatter={(value) => `${value}分`}
      parser={(value) => (value ?? "").replace("分", "")}
      min={0}
    />
  </EditShopFormItem>
));
