import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Form } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { BankCode, BranchCode } from "hooks/useBankCode";
import { Company, GmoBankAccount, GmoBankAccountShop, Shop } from "types/graphql";

import { AccountHolderField } from "./AccountHolderField";
import { AccountNumberField } from "./AccountNumberField";
import { AccountTypeField } from "./AccountTypeField";
import { BankField } from "./BankField";
import { BranchField } from "./BranchField";
import { NameField } from "./NameField";
import { ShopField } from "./ShopField";
import {
  ACCOUNT_TYPES,
  EditGmoBankAccountFormValues,
  GmoBankAccountDetail,
  useEditGmoBankAccountForm,
} from "./useEditGmoBankAccountForm";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type Props = {
  onSubmit: (formValues: EditGmoBankAccountFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  fetchBranchCode: (args: { bankCode: string }) => void;
  loading: boolean;
  gmoBankAccount: Pick<GmoBankAccount, "id" | "name">;
  gmoBankAccountShops: Pick<GmoBankAccountShop, "shopId" | "depositCycleType">[];
  gmoBankAccountDetail: GmoBankAccountDetail;
  shopsByCompany: (Pick<Company, "name"> & { shops: Pick<Shop, "shopId" | "name">[] })[];
  banks: BankCode[];
  branches: BranchCode[];
  defaultBranchCode: string;
};

export const EditGmoBankAccountForm = memo<Props>(
  ({
    onSubmit,
    onFormValidationError,
    fetchBranchCode,
    loading,
    gmoBankAccount,
    gmoBankAccountShops,
    gmoBankAccountDetail,
    shopsByCompany,
    banks,
    branches,
    defaultBranchCode,
  }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const { form, initialValues, submit } = useEditGmoBankAccountForm({
      gmoBankAccount,
      gmoBankAccountDetail,
      gmoBankAccountShops,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    const onFormValuesChange = useCallback(
      (changeValue: { bank: string }) => {
        const { bank } = changeValue;
        if (bank && typeof bank === "string") {
          form.setFieldValue("branch", "");
          fetchBranchCode({ bankCode: bank });
        }
      },
      [form, fetchBranchCode],
    );

    const hasBank = Boolean(form.getFieldValue("bank"));

    return (
      <Wrapper>
        <PageHeader title={gmoBankAccount.name} onBack={goBack} />
        <StyledForm
          name="editGmoBankAccount"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={onFormValuesChange}
        >
          <FormSection>
            <NameField />
            <BankField banks={banks} />
            <BranchField disabled={!hasBank} defaultValue={defaultBranchCode} branches={branches} />
            <AccountTypeField disabled={!hasBank} accountTypes={ACCOUNT_TYPES} />
            <AccountNumberField />
            <AccountHolderField />
            <ShopField shopsByCompany={shopsByCompany} />
          </FormSection>
        </StyledForm>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={goBack}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </Wrapper>
    );
  },
);
