import React, { memo, useCallback, useMemo } from "react";
import { Select } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { Company, Shop } from "types/graphql";

import {
  AddGmoBankAccountFormItem,
  AddGmoBankAccountFormValues,
} from "../useAddGmoBankAccountForm";

type Props = {
  shopsByCompany: (Pick<Company, "name"> & { shops: Pick<Shop, "shopId" | "name">[] })[];
} & Omit<FormItemProps, "children" | "name">;

export const ShopField = memo<Props>(({ shopsByCompany, ...props }) => {
  const options = useMemo(
    () =>
      shopsByCompany.map(({ name, shops }) => ({
        label: name,
        options: shops.map(({ shopId, name }) => ({
          label: name,
          value: shopId,
        })),
      })),
    [shopsByCompany],
  );

  const validator = useCallback(
    async (_: unknown, value: AddGmoBankAccountFormValues["depositCycleShopIds"]) => {
      const { sixAMonthShopIds, twiceAMonthShopIds } = value;
      if (sixAMonthShopIds.length === 0 && twiceAMonthShopIds.length === 0) {
        throw new Error("対象店舗を入力してください");
      }

      if (sixAMonthShopIds.some((shopId: string) => twiceAMonthShopIds.includes(shopId))) {
        throw new Error("入金月6回と入金月2回に同じ店舗が含まれています");
      }
    },
    [],
  );

  return (
    <AddGmoBankAccountFormItem.NonProperty noStyle>
      <AddGmoBankAccountFormItem
        label="対象店舗"
        name="depositCycleShopIds"
        rules={[{ required: true }, { validator }]}
        {...props}
      >
        <AddGmoBankAccountFormItem.NonProperty
          noStyle
          shouldUpdate={withFormDependencies(({ depositCycleShopIds: shops }) => [
            shops.sixAMonthShopIds,
            shops.twiceAMonthShopIds,
          ])}
        >
          {({ getFieldValue }) => {
            const getSelectableOptions = (fieldName: string) =>
              options.map(({ label, options }) => ({
                label,
                options: options.map(({ label, value }) => ({
                  label,
                  value,
                  disabled: getFieldValue(["depositCycleShopIds", fieldName]).includes(value),
                })),
              }));

            return (
              <>
                <AddGmoBankAccountFormItem
                  label="入金月6回"
                  name={["depositCycleShopIds", "sixAMonthShopIds"]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="label"
                    options={getSelectableOptions("twiceAMonthShopIds")}
                  />
                </AddGmoBankAccountFormItem>
                <AddGmoBankAccountFormItem
                  label="入金月2回"
                  name={["depositCycleShopIds", "twiceAMonthShopIds"]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="label"
                    options={getSelectableOptions("sixAMonthShopIds")}
                  />
                </AddGmoBankAccountFormItem>
              </>
            );
          }}
        </AddGmoBankAccountFormItem.NonProperty>
      </AddGmoBankAccountFormItem>
    </AddGmoBankAccountFormItem.NonProperty>
  );
});
