import React, { useCallback } from "react";
import styled from "styled-components";
import { TablePaginationConfig, Tooltip } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import { ColumnsType } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { Pagination } from "hooks/usePagination";

import {
  MenuOrderItemAnalyticsRow,
  MenuOrderItemAnalyticsRowWithSortedIndex,
  RepeaterTableType,
  ReportByType,
  Sort,
  SortKey,
} from "../types";

import { renderMenuOrderItemAnalyticsCell } from "./renderMenuOrderItemAnalyticsCell";

export const defaultFixedDecimalPointForDisplay = 1;

const CenteredTitle = styled.span`
  text-align: center;
`;

const TableStyleProvider = styled.div`
  .ant-table-container {
    border: none;
  }
`;

const ColumnTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
`;

const ColumnSubtitle = styled.div`
  font-size: 10px;
  font-weight: 500;
`;

const formatter = new Intl.NumberFormat("ja");

type Props = {
  rows: MenuOrderItemAnalyticsRowWithSortedIndex[];
  isLoading: boolean;
  reportByType: ReportByType;
  repeaterTableType: RepeaterTableType;
  sortedInfo: Sort;
  pagination: Pagination;
  onPaginationChange: (newPagination: PaginationConfig) => void;
  onSortChange: (newSort: Sort) => void;
  showTaxIncluded: boolean;
};

export const MenuOrderItemAnalyticsTable = ({
  rows,
  isLoading,
  reportByType,
  repeaterTableType,
  sortedInfo,
  pagination,
  onPaginationChange,
  onSortChange,
  showTaxIncluded,
}: Props) => {
  const handleChange = useCallback(
    (
      { position: _, ...pagination }: TablePaginationConfig,
      __: unknown,
      sorter: SorterResult<MenuOrderItemAnalyticsRow> | SorterResult<MenuOrderItemAnalyticsRow>[],
    ) => {
      onPaginationChange(pagination);
      if (!Array.isArray(sorter)) {
        onSortChange({
          column: sorter.field as SortKey,
          direction: sorter.order ?? "ascend",
        });
      } else {
        onSortChange({
          column: sorter[0]?.field as SortKey,
          direction: sorter[0]?.order ?? "ascend",
        });
      }
    },
    [onPaginationChange, onSortChange],
  );

  const hideAbcRank = reportByType === ReportByType.menuType;
  const nameColumnTitle =
    reportByType === ReportByType.category
      ? "カテゴリ名"
      : reportByType === ReportByType.menuType
      ? "メニュータイプ"
      : "商品名";

  const repeatPercentageColumn: ColumnsType<MenuOrderItemAnalyticsRow>[number] = {
    align: "right" as const,
    width: 150,
    title: (
      <span>
        <ColumnTitle>
          リピート率
          <Spacer size={3} inline />
          <Tooltip placement="top" title="再来店時に同メニューを注文した割合">
            <QuestionCircleOutlined />
          </Tooltip>
        </ColumnTitle>
      </span>
    ),
    dataIndex: "repeaterPercentage",
    render: (_: number, { repeaterPercentage }: MenuOrderItemAnalyticsRow) =>
      `${
        repeaterTableType === RepeaterTableType.nonRepeaterTables
          ? "-"
          : (repeaterPercentage * 100).toFixed(defaultFixedDecimalPointForDisplay)
      }%`,
    sorter: (a, b) => a.repeaterPercentage - b.repeaterPercentage,
    sortOrder: sortedInfo.column === "repeaterPercentage" ? sortedInfo.direction : null,
  };

  const columns: ColumnsType<MenuOrderItemAnalyticsRow> = [
    {
      title: <CenteredTitle>順位</CenteredTitle>,
      width: 100,
      align: "center",
      dataIndex: "sortedIndex",
    },
    {
      title: nameColumnTitle,
      dataIndex: "name",
    },
    {
      title: (
        <ColumnTitle>
          出数構成比<ColumnSubtitle>（出数合計）</ColumnSubtitle>
        </ColumnTitle>
      ),
      dataIndex: "orderedQuantity",
      align: "right",
      width: 150,
      render: (_, { orderedQuantity }) =>
        renderMenuOrderItemAnalyticsCell({
          actualPercentage: orderedQuantity.actualPercentage,
          subTitle: formatter.format(orderedQuantity.actualValue),
          abcRank: orderedQuantity.abcRank,
          hideAbcRank,
        }),
      sorter: (a, b) => a.orderedQuantity.actualValue - b.orderedQuantity.actualValue,
      sortOrder: sortedInfo.column === "orderedQuantity" ? sortedInfo.direction : null,
    },
    {
      title: (
        <ColumnTitle>
          売上構成比<ColumnSubtitle>（売上合計）</ColumnSubtitle>
        </ColumnTitle>
      ),
      dataIndex: showTaxIncluded ? "taxIncludedAmount" : "taxExcludedAmount",
      align: "right",
      width: 160,
      render: (_, { taxExcludedAmount, taxIncludedAmount }) => {
        const amount = showTaxIncluded ? taxIncludedAmount : taxExcludedAmount;
        return renderMenuOrderItemAnalyticsCell({
          actualPercentage: amount.actualPercentage,
          subTitle: `¥${formatter.format(amount.actualValue)}`,
          abcRank: amount.abcRank,
          hideAbcRank,
        });
      },
      sorter: (a, b) => a.taxExcludedAmount.actualValue - b.taxExcludedAmount.actualValue,
      sortOrder:
        sortedInfo.column === "taxExcludedAmount" || sortedInfo.column === "taxIncludedAmount"
          ? sortedInfo.direction
          : null,
    },
    {
      title: (
        <ColumnTitle>
          粗利構成比<ColumnSubtitle>（粗利合計）</ColumnSubtitle>
        </ColumnTitle>
      ),
      dataIndex: showTaxIncluded ? "taxIncludedNetProfitAmount" : "taxExcludedNetProfitAmount",
      align: "right",
      width: 160,
      render: (_, { taxIncludedNetProfitAmount, taxExcludedNetProfitAmount }) => {
        const amount = showTaxIncluded ? taxIncludedNetProfitAmount : taxExcludedNetProfitAmount;
        return renderMenuOrderItemAnalyticsCell({
          actualPercentage: amount.actualPercentage,
          subTitle: `¥${formatter.format(amount.actualValue)}`,
          abcRank: amount.abcRank,
          hideAbcRank,
        });
      },
      sorter: (a, b) =>
        a.taxExcludedNetProfitAmount.actualValue - b.taxExcludedNetProfitAmount.actualValue,
      sortOrder:
        sortedInfo.column === "taxExcludedNetProfitAmount" ||
        sortedInfo.column === "taxIncludedNetProfitAmount"
          ? sortedInfo.direction
          : null,
    },
    ...(reportByType === ReportByType.menu ? [repeatPercentageColumn] : []),
  ];

  return (
    <TableStyleProvider>
      <Table
        key={reportByType}
        loading={isLoading}
        columns={columns}
        dataSource={rows}
        bordered
        pagination={pagination}
        onChange={handleChange}
        scroll={{ x: 1136 }}
        rowKey="id"
        sortDirections={["descend", "ascend"]}
      />
    </TableStyleProvider>
  );
};
