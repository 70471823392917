import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Descriptions, FormInstance, Radio, RadioChangeEvent, Select, Space, Tag } from "antd";
import { emptyMenusFilter } from "models/customerSegment";

import { InputNumber } from "components/Input/InputNumber";
import { Spacer } from "components/Spacer";
import { Menu } from "pages/EditMessageDelivery/types";

import {
  EditMessageDeliveryFormItem,
  EditMessageDeliveryFormValues,
} from "../useEditMessageDeliveryForm";

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectOption = Select.Option;

const StyledSelect = styled(Select)`
  width: 100%;
`;

type Props = {
  form: FormInstance<EditMessageDeliveryFormValues>;
  menus: Menu[];
};

const generateDescriptionsItems = (menu: Menu) => [
  {
    key: `${menu.id}-category`,
    label: "カテゴリ",
    children: menu.categoryMenus.map((categoryMenu) => (
      <Tag key={`${categoryMenu.categoryId}:${menu.id}`}>{categoryMenu.category.name}</Tag>
    )),
  },
];

export const OrderedMenusFilter = memo<Props>(({ menus, form }) => {
  const [shouldFilter, setShouldFilter] = useState<"true" | "false">(
    (form.getFieldValue(["orderedMenusFilter", "menuIds"]) ?? []).length === 0 ? "false" : "true",
  );

  const revalidate = useCallback(
    () => form.validateFields([["orderedMenusFilter", "menuIds"]]),
    [form],
  );

  const validator = useCallback(
    async (
      _: unknown,
      menuIds:
        | NonNullable<EditMessageDeliveryFormValues["orderedMenusFilter"]>["menuIds"]
        | undefined,
    ) => {
      if (shouldFilter === "true" && !menuIds?.length) {
        throw new Error("メニューを選択してください");
      }
    },
    [shouldFilter],
  );

  const onChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value;

      if (value === "true" || value === "false") {
        setShouldFilter(value);
      }

      if (value === "false") {
        form.setFieldsValue({ orderedMenusFilter: emptyMenusFilter });
        revalidate();
      }
    },
    [form, revalidate],
  );

  return (
    <Content>
      <Radio.Group value={shouldFilter} onChange={onChangeRadio}>
        <Space direction="vertical">
          <Radio value="false">絞り込まない</Radio>
          <Radio value="true">絞り込む</Radio>
        </Space>
      </Radio.Group>

      <Spacer size={8} />
      <EditMessageDeliveryFormItem
        name={["orderedMenusFilter", "menuIds"]}
        noStyle
        rules={[() => ({ validator })]}
      >
        <StyledSelect
          showSearch
          optionFilterProp="label"
          placeholder="メニューを選択"
          disabled={shouldFilter === "false"}
          mode="multiple"
          optionLabelProp="label"
        >
          {menus.map((menu) => (
            <SelectOption key={menu.id} label={menu.name} value={menu.id}>
              {menu.name}
              <Descriptions column={1} items={generateDescriptionsItems(menu)} />
            </SelectOption>
          ))}
        </StyledSelect>
      </EditMessageDeliveryFormItem>
      <Spacer size={4} />
      <Space size="small">
        上記のメニューのいずれかをトータル
        <EditMessageDeliveryFormItem
          name={["orderedMenusFilter", "moreThanOrEqualQuantity"]}
          noStyle
        >
          <InputNumber style={{ width: "88px" }} disabled={shouldFilter === "false"} min={1} />
        </EditMessageDeliveryFormItem>
        回以上注文
      </Space>
      <EditMessageDeliveryFormItem
        name="orderedMenusFilterValidation"
        noStyle
        rules={[
          ({ getFieldValue }) => ({
            validator: async () => {
              const menuIds = getFieldValue(["orderedMenusFilter", "menuIds"]);
              const moreThanOrEqualQuantity = getFieldValue([
                "orderedMenusFilter",
                "moreThanOrEqualQuantity",
              ]);
              if (Array.isArray(menuIds) && menuIds.length > 0 && !moreThanOrEqualQuantity) {
                throw new Error("注文回数を入力してください");
              }
            },
          }),
        ]}
      />
    </Content>
  );
});
