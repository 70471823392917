import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button } from "antd";
import dayjs from "dayjs";
import { getTimeForCompare } from "models/orderableTime";
import { normalizeHoursAndMinutes } from "util/time";

import { TimePicker } from "components/antd/TimePicker";
import { FormActions } from "components/Form/FormActions";
import { DayWeekKeys } from "pages/EditOrderableTime/EditOrderableTimeForm/DayWeekScheduler/constants";
import { WeeklyEvent } from "pages/EditOrderableTime/EditOrderableTimeForm/DayWeekScheduler/types";

const FormContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export type EventFormValues = {
  key: string;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  dayWeek: (typeof DayWeekKeys)[number];
};

type Props = {
  event: WeeklyEvent;
  changeDateTime: dayjs.Dayjs;
  formValues: EventFormValues;
  setFromValues: (formValues: EventFormValues | null) => void;
  onCreateEvent: (_: WeeklyEvent) => void;
  onDeleteEvent: (_: WeeklyEvent) => void;
};

export const EditEventForm = memo<Props>(
  ({ event, changeDateTime, formValues, setFromValues, onCreateEvent, onDeleteEvent }) => {
    const onSetFormValues = useCallback(
      (formValues: EventFormValues | null) => {
        if (!formValues) return setFromValues(formValues);

        const { start, end } = formValues;
        const comparableStart = getTimeForCompare({
          time: normalizeHoursAndMinutes(start),
          changeDateTime,
        });
        const comparableEnd = getTimeForCompare({
          time: normalizeHoursAndMinutes(end),
          changeDateTime,
        });

        if (comparableEnd.isBefore(comparableStart)) {
          return setFromValues({ ...formValues, start: end, end: start });
        }

        return setFromValues(formValues);
      },
      [changeDateTime, setFromValues],
    );

    return (
      <>
        <FormContent>
          <TimePicker
            formItemName="start"
            value={formValues?.start}
            onChange={(start) => onSetFormValues(start ? { ...formValues, start } : null)}
            showNow={false}
            allowClear={false}
          />
          <span>~</span>
          <TimePicker
            formItemName="end"
            value={formValues?.end}
            onChange={(end) => onSetFormValues(end ? { ...formValues, end } : null)}
            showNow={false}
            allowClear={false}
          />
        </FormContent>
        <FormActions>
          <Button
            type="primary"
            onClick={() => {
              onDeleteEvent(event);
              onCreateEvent(formValues);
            }}
          >
            保存
          </Button>
        </FormActions>
      </>
    );
  },
);
