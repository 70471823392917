import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanGroupHeader } from "components/PageHeader/PlanGroupHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { AddPlanGroupPlanModal } from "pages/PlanGroupPlans/AddPlanGroupModal/index";
import { PlanTable } from "pages/PlanGroupPlans/PlanTable";
import {
  usePlanGroupPlansDeletePlanGroupMutation,
  usePlanGroupPlansGetPlanGroupQuery,
} from "pages/PlanGroupPlans/queries";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const PlanGroupPlans = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planGroupId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanGroupData,
    loading: loadingPlanGroup,
    refetch: refetchPlanGroup,
    error,
  } = usePlanGroupPlansGetPlanGroupQuery(
    !Number.isNaN(planGroupId) ? { variables: { planGroupId } } : { skip: true },
  );
  const planGroup = getPlanGroupData?.planGroup?.[0];

  setCompany(planGroup?.companyId);

  const plans = planGroup?.plans ?? [];

  const [PlanGroupPlansDeletePlanGroupMutation, { loading: loadingUpdatePlanGroup }] =
    usePlanGroupPlansDeletePlanGroupMutation();

  const onRemove = useCallback(
    async (planId: number) => {
      try {
        await PlanGroupPlansDeletePlanGroupMutation({
          variables: { planId },
        });
        await refetchPlanGroup();
        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
    },
    [refetchPlanGroup, PlanGroupPlansDeletePlanGroupMutation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);
  const goBackToPlanGroupPlan = useCallback(() => {
    navigate(`/planGroup/${planGroupId}/plan`, { replace: true });
  }, [navigate, planGroupId]);

  return (
    <DashboardLayout
      title={planGroup?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラングループ" }, { name: "プラン" }],
      }}
    >
      <PlanGroupHeader planGroup={planGroup ?? null} onBack={goBack} />
      {loadingPlanGroup && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planGroup && (
        <FormContent>
          <ButtonWrapper>
            <Link key="addPlan" to={`/planGroup/${planGroupId}/plan/add`} replace>
              <Button type="primary">追加</Button>
            </Link>
          </ButtonWrapper>
          <PlanTable plans={plans} onRemove={onRemove} loading={loadingUpdatePlanGroup} />
          <Routes>
            <Route
              path="add"
              element={
                <AddPlanGroupPlanModal
                  planGroup={planGroup}
                  goBack={goBackToPlanGroupPlan}
                  refetchPlanGroup={() => {
                    refetchPlanGroup();
                  }}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
