import React, { memo, useCallback } from "react";
import dayjs from "dayjs";

import { QuestionnaireScoreChangesDimensionType } from "types/graphql";

type Props = {
  x?: number;
  y?: number;
  fill?: string;
  payload?: {
    value: string;
    index: number;
  };
  style: {
    fontSize: number;
    fontWeight: number;
    color: string;
  };
  dimension: QuestionnaireScoreChangesDimensionType;
};

export const XAxisCustomizedTick = memo<Props>(({ x, y, fill, payload, style, dimension }) => {
  const xAxisTickFormatter = useCallback(
    (value: string, index: number) => {
      if (dimension === QuestionnaireScoreChangesDimensionType.Month) {
        const valueMoment = dayjs(value, "YYYY年M月");
        return valueMoment.month() === 0 || index === 0
          ? `${valueMoment.format("M月")}\n${valueMoment.format("YYYY年")}`
          : valueMoment.format("M月");
      }
      const valueMoment = dayjs(value, "M月D日");
      return valueMoment.date() === 1 || index === 0
        ? `${valueMoment.format("D日")}\n${valueMoment.format("M月")}`
        : valueMoment.format("D日");
    },
    [dimension],
  );
  if (!payload) return null;
  const { value, index } = payload;
  return (
    <text x={x} y={y} dy={8} fill={fill} style={style}>
      {xAxisTickFormatter(value, index)
        .split("\n")
        .map((text: string, i: number) => (
          <tspan key={text} textAnchor="middle" x={x} y={(y ?? 0) + i * 24}>
            {text}
          </tspan>
        ))}
    </text>
  );
});
