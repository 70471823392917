import React, { Fragment, ReactNode } from "react";
import styled from "styled-components";
import { Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { red } from "@ant-design/colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { isNumber } from "lodash";
import {
  UpdatingMenu,
  UpdatingOption,
  ValidatedCsvChoice,
  ValidatedCsvMenu,
  ValidatedCsvOption,
} from "models/menuMasterCsv";
import { menuTypes } from "models/menuType";
import { taxMethods } from "models/taxMethod";

import { Spacer } from "components/Spacer";
import { CreateTranslationSource, Maybe, SupportedLocaleEnumType } from "types/graphql";

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${red[5]};
`;

const StyledTag = styled(Tag)`
  margin: 4px;

  &:first-child {
    margin-left: 0;
  }
`;

const getTranslationCellRenderOptions = ({
  columnName,
  locale,
  translations,
  changingTranslations,
}: {
  columnName: UpdatingMenu["changingTranslations"][number]["columnName"];
  locale: SupportedLocaleEnumType;
  translations: CreateTranslationSource[];
  changingTranslations: UpdatingMenu["changingTranslations"];
}) => {
  const foundTranslation = translations.find(
    (translation) => locale === translation.locale.toUpperCase(),
  );
  const updatedLocales =
    changingTranslations
      .find((changedTranslation) => changedTranslation.columnName === columnName)
      ?.locales.map((locale) => locale.toUpperCase()) ?? [];

  return {
    isUpdated: Boolean(updatedLocales.includes(locale)),
    element: foundTranslation?.value ?? "",
  };
};

const getTranslationCellRenderOptionsForOption = ({
  isCreated,
  columnName,
  locale,
  translations,
  changingTranslations,
}: {
  isCreated?: boolean;
  columnName: UpdatingOption["changingTranslations"][number]["columnName"];
  locale: SupportedLocaleEnumType;
  translations: CreateTranslationSource[];
  changingTranslations: UpdatingOption["changingTranslations"];
}) => {
  const foundTranslation = translations.find(
    (translation) => locale === translation.locale.toUpperCase(),
  );
  const updatedLocales =
    changingTranslations
      .find((changedTranslation) => changedTranslation.columnName === columnName)
      ?.locales.map((locale) => locale.toUpperCase()) ?? [];

  return {
    isCreated,
    isUpdated: Boolean(updatedLocales.includes(locale)),
    element: foundTranslation?.value ?? "",
  };
};

const toStringNullableNumber = (value: Maybe<number>) => (isNumber(value) ? value.toString() : "");
const toStringTaxRate = (value: Maybe<number>) => (isNumber(value) ? `${value * 100}%` : "");
const renderMultipleLineElement = (value: Maybe<string>) => {
  if (!value) return "";
  return value.split("\n").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
};

type TranslationSourceColumnName = "name" | "description" | "featuredLabelText";

type ValidatedCsvMenuWithChanges = ValidatedCsvMenu & {
  changingMenuColumns?: UpdatingMenu["changingMenuColumns"];
  changingTranslations?: UpdatingMenu["changingTranslations"];
};

export type ValidatedCsvOptionWithChanges = (ValidatedCsvOption | ValidatedCsvChoice) & {
  changingOptionColumns?: UpdatingOption["changingOptionColumns"] | never[];
  changingChoiceColumns?: UpdatingOption["changingChoices"][number]["changingColumns"] | never[];
  changingTranslations?: UpdatingOption["changingTranslations"] | never[];
};

type MenuTableColumnType = ColumnsType<ValidatedCsvMenuWithChanges>;
type OptionTableColumnType = ColumnsType<ValidatedCsvOptionWithChanges>;

type Column<DataIndexType, RecordType> = {
  title: string;
  width?: number;
  dataIndex?: DataIndexType;
  render?: (value: null, record: RecordType, index: number) => React.ReactNode;
  translationDataIndex?: {
    locale: SupportedLocaleEnumType;
    columnName: TranslationSourceColumnName;
  };
};

type MenuColumn = Column<UpdatingMenu["changingMenuColumns"][number], ValidatedCsvMenuWithChanges>;

type OptionColumn = Column<
  UpdatingOption["changingOptionColumns"][number],
  ValidatedCsvOptionWithChanges
>;

const translationColumnNameToSourceName = new Map<
  TranslationSourceColumnName,
  "nameSources" | "descriptionSources" | "featureLabelSources"
>([
  ["name", "nameSources"],
  ["description", "descriptionSources"],
  ["featuredLabelText", "featureLabelSources"],
]);

const withCellStyles = ({
  isUpdated,
  isConflicted,
  isCreated,
  element,
}: {
  isUpdated: boolean;
  isConflicted: boolean;
  isCreated?: boolean;
  element: ReactNode;
}) => ({
  props: {
    style: {
      fontWeight: isUpdated ? "bold" : "normal",
      ...(isUpdated
        ? {
            backgroundColor: isConflicted ? "#F06C631A" : "#FEFFE6",
          }
        : {}),
      ...(isCreated
        ? {
            backgroundColor: "#d6ffd6",
          }
        : {}),
    },
  },
  children: element,
});

const createStyleParamsAndElement = ({
  column,
  menuRow,
  index,
}: {
  column: MenuColumn;
  menuRow: ValidatedCsvMenuWithChanges;
  index: number;
}) => {
  if (column.translationDataIndex) {
    const translationSource = translationColumnNameToSourceName.get(
      column.translationDataIndex.columnName,
    );

    return {
      isConflicted: menuRow.isConflicted,
      ...getTranslationCellRenderOptions({
        columnName: column.translationDataIndex.columnName,
        changingTranslations: menuRow.changingTranslations ?? [],
        locale: column.translationDataIndex.locale,
        translations: translationSource ? menuRow.translations[translationSource] : [],
      }),
    };
  }

  return {
    isUpdated: column.dataIndex
      ? (menuRow.changingMenuColumns ?? []).includes(column.dataIndex)
      : false,
    isConflicted: menuRow.isConflicted,
    element: column.render
      ? column.render(null, menuRow, index)
      : column.dataIndex && column.dataIndex !== "categories"
      ? menuRow[column.dataIndex]
      : null,
  };
};

const createStyleParamsAndElementForOption = ({
  column,
  optionRow,
  index,
}: {
  column: OptionColumn;
  optionRow: ValidatedCsvOptionWithChanges;
  index: number;
}) => {
  const isCreated = "_choiceId" in optionRow && optionRow._choiceId === null;
  if (column.translationDataIndex) {
    return {
      isConflicted: false, //optionRow.isConflicted,
      ...getTranslationCellRenderOptionsForOption({
        isCreated,
        columnName: column.translationDataIndex.columnName,
        changingTranslations: optionRow.changingTranslations ?? [],
        locale: column.translationDataIndex.locale,
        translations: optionRow.translations["nameSources"],
      }),
    };
  }

  return {
    isUpdated: column.dataIndex
      ? (optionRow.changingOptionColumns ?? [])
          .concat(optionRow.changingChoiceColumns ?? [])
          .includes(column.dataIndex)
      : false,
    isConflicted: false,
    isCreated,
    element: column.render ? column.render(null, optionRow, index) : column.dataIndex,
  };
};

const getWrappedColumns = (columns: MenuColumn[]): MenuTableColumnType =>
  columns.map((column) => ({
    title: column.title,
    render: (_, menuRow, index) =>
      withCellStyles(
        createStyleParamsAndElement({
          column,
          menuRow,
          index,
        }),
      ),
  }));

const getWrappedColumnsForOptions = (columns: OptionColumn[]): OptionTableColumnType =>
  columns.map((column) => ({
    title: column.title,
    render: (_, optionRow, index) =>
      withCellStyles(
        createStyleParamsAndElementForOption({
          column,
          optionRow,
          index,
        }),
      ),
  }));

export const updatingMenusColumns: MenuTableColumnType = getWrappedColumns([
  {
    title: "メニュー名",
    dataIndex: "name",
    width: 180,
    render: (_, { isConflicted, name }) => (
      <>
        {isConflicted ? (
          <>
            <Tooltip title="CSVファイルのダウンロード後に、メニューが更新されています。更新された項目（ピンク色の箇所）をご確認ください。">
              <StyledExclamationCircleOutlined />
            </Tooltip>
            <Spacer size={5} inline />
          </>
        ) : null}
        {name}
      </>
    ),
  },
  {
    title: "ハンディ表示名",
    dataIndex: "shopSideName",
    width: 180,
  },
  {
    title: "伝票表示名",
    dataIndex: "receiptDisplayName",
    width: 180,
  },
  {
    title: "カテゴリ",
    dataIndex: "categories",
    render: (_, { existingCategoryMenus, addingCategoryMenus, removingCategoryMenus }) => (
      <>
        {existingCategoryMenus.map(({ categoryName }) => (
          <StyledTag key={categoryName}>{categoryName}</StyledTag>
        ))}
        {addingCategoryMenus.map(({ categoryName }) => (
          <StyledTag key={categoryName} color="blue">
            {categoryName}
          </StyledTag>
        ))}
        {removingCategoryMenus.map(({ categoryName }) => (
          <StyledTag key={categoryName} color="red">
            {categoryName}
          </StyledTag>
        ))}
      </>
    ),
    width: 180,
  },
  {
    title: "表示時間帯",
    dataIndex: "orderableTimeId",
    width: 120,
    render: (_, { orderableTimeName }) => orderableTimeName,
  },
  {
    title: "メニュータイプ",
    dataIndex: "menuType",
    render(_, { menuType }) {
      return menuType && menuType in menuTypes ? menuTypes[menuType as keyof typeof menuTypes] : "";
    },
    width: 120,
  },
  {
    title: "説明文",
    dataIndex: "description",
    width: 200,
    render: (_, { description }) => renderMultipleLineElement(description ?? ""),
  },
  {
    title: "特集ラベル",
    dataIndex: "featuredLabelText",
    width: 140,
  },
  {
    title: "税種別",
    dataIndex: "taxMethod",
    width: 100,
    render: (_, { taxMethod }) => taxMethods[taxMethod] ?? "",
  },
  {
    title: "税率",
    dataIndex: "taxRate",
    width: 80,
    render: (_, { taxRate }) => toStringTaxRate(taxRate),
  },
  {
    title: "価格設定",
    dataIndex: "openPrice",
    width: 120,
    render: (_, { openPrice }) => (openPrice ? "フリーキー" : "固定"),
  },
  {
    title: "販売価格",
    dataIndex: "price",
    width: 120,
  },
  {
    title: "原価税率",
    dataIndex: "costTaxRate",
    width: 120,
    render: (_, { costTaxRate }) => (costTaxRate ? toStringTaxRate(costTaxRate) : ""),
  },
  {
    title: "原価",
    dataIndex: "costPrice",
    width: 120,
    render: (_, { costPrice }) => (costPrice ? toStringNullableNumber(costPrice) : ""),
  },
  {
    title: "卓ごと上限数",
    dataIndex: "orderMaxNum",
    width: 120,
    render: (_, { orderMaxNum }) => (orderMaxNum ? toStringNullableNumber(orderMaxNum) : ""),
  },
  {
    title: "1組・来店ごと上限数",
    dataIndex: "orderMaxNumPerTableUser",
    width: 120,
    render: (_, { orderMaxNumPerTableUser }) =>
      orderMaxNumPerTableUser ? toStringNullableNumber(orderMaxNumPerTableUser) : "",
  },
  {
    title: "1名・注文ごと上限数",
    dataIndex: "unitMaxOrderNumForNumPeople",
    width: 120,
    render: (_, { unitMaxOrderNumForNumPeople }) =>
      unitMaxOrderNumForNumPeople ? toStringNullableNumber(unitMaxOrderNumForNumPeople) : "",
  },
  {
    title: "1名・来店ごと上限数",
    dataIndex: "unitMaxOrderNumPerCustomer",
    width: 120,
    render: (_, { unitMaxOrderNumPerCustomer }) =>
      unitMaxOrderNumPerCustomer !== undefined
        ? toStringNullableNumber(unitMaxOrderNumPerCustomer)
        : "",
  },
  {
    title: "メニュー名：英語",
    width: 180,
    translationDataIndex: {
      columnName: "name",
      locale: SupportedLocaleEnumType.EnUs,
    },
  },
  {
    title: "メニュー名：中国語(簡体字)",
    width: 180,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.ZhCn,
      columnName: "name",
    },
  },
  {
    title: "メニュー名：韓国語",
    width: 180,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.KoKr,
      columnName: "name",
    },
  },
  {
    title: "説明文：英語",
    width: 200,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.EnUs,
      columnName: "description",
    },
  },
  {
    title: "説明文：中国語(簡体字)",
    width: 200,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.ZhCn,
      columnName: "description",
    },
  },
  {
    title: "説明文：韓国語",
    width: 200,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.KoKr,
      columnName: "description",
    },
  },
  {
    title: "特集ラベル：英語",
    width: 180,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.EnUs,
      columnName: "featuredLabelText",
    },
  },
  {
    title: "特集ラベル：中国語(簡体字)",
    width: 140,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.ZhCn,
      columnName: "featuredLabelText",
    },
  },
  {
    title: "特集ラベル：韓国語",
    width: 140,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.KoKr,
      columnName: "featuredLabelText",
    },
  },
]);

export const deletingMenusColumns: ColumnsType<{
  _menuId: number;
  name: string;
}> = [
  {
    title: "メニューID",
    dataIndex: "_menuId",
    width: 110,
  },
  {
    title: "メニュー名",
    dataIndex: "name",
    width: 110,
  },
];

export const updatingOptionsColumns: OptionTableColumnType = getWrappedColumnsForOptions([
  {
    title: "名称",
    dataIndex: "name",
    width: 80,
    render: (_, { name }) => <>{name}</>,
  },
  {
    title: "伝票表示名",
    dataIndex: "receiptDisplayName",
    render: (_, row) => row.receiptDisplayName,
    width: 80,
  },
  {
    title: "最小選択数",
    dataIndex: "maxChoiceNum",
    width: 40,
    render: (_, row) => {
      if (!("minChoiceNum" in row)) {
        return "";
      }
      return toStringNullableNumber(row.minChoiceNum);
    },
  },
  {
    title: "最大選択数",
    dataIndex: "maxChoiceNum",
    width: 40,
    render: (_, row) => {
      if (!("maxChoiceNum" in row)) {
        return "";
      }
      return row.maxChoiceNum === undefined ? "" : toStringNullableNumber(row.maxChoiceNum);
    },
  },
  {
    title: "チョイス：メニュータイプ",
    dataIndex: "menuType",
    render(_, row) {
      if (!("menuType" in row)) {
        return "";
      }
      const { menuType } = row;
      return menuType && menuType in menuTypes ? menuTypes[menuType as keyof typeof menuTypes] : "";
    },
    width: 50,
  },

  {
    title: "チョイス：価格",
    dataIndex: "price",
    render: (_, row) =>
      "price" in row ? (row.price === undefined ? "" : toStringNullableNumber(row.price)) : "",
    width: 120,
  },
  {
    title: "チョイス：原価",
    dataIndex: "costPrice",
    width: 120,
    render: (_, row) =>
      "costPrice" in row
        ? row.costPrice === undefined
          ? ""
          : toStringNullableNumber(row.costPrice)
        : "",
  },
  {
    title: "チョイス：税率",
    dataIndex: "costTaxRate",
    width: 80,
    render: (_, row) =>
      "costTaxRate" in row
        ? row.costTaxRate === undefined
          ? ""
          : toStringTaxRate(row.costTaxRate)
        : "",
  },
  {
    title: "名称：英語",
    width: 180,
    translationDataIndex: {
      columnName: "name",
      locale: SupportedLocaleEnumType.EnUs,
    },
  },
  {
    title: "名称：中国語(簡体字)",
    width: 180,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.ZhCn,
      columnName: "name",
    },
  },
  {
    title: "名称：韓国語",
    width: 180,
    translationDataIndex: {
      locale: SupportedLocaleEnumType.KoKr,
      columnName: "name",
    },
  },
]);
