import React, { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ExpandableConfig } from "antd/es/table/interface";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { couponAnalyticsProperties } from "models/couponAnalytics";

import { TableContent } from "components/AnalyticsTable/TableContent";
import { TableHeaderTitle } from "components/AnalyticsTable/TableHeaderTitle";
import { TableStyleModifier } from "components/AnalyticsTable/TableStyleModifier";
import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { colors } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { CouponAnalyticsSummaryPerCouponItem, CouponUsage } from "../type";

type DataSourceSingle = {
  key: string;
  name: string;
  image: string | null;
  isShopRow: boolean;
} & CouponUsage;

type DataSource = DataSourceSingle & {
  children: DataSourceSingle[];
};

const Name = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const imageStyles = css`
  width: 64px;
  height: 64px;
`;

const Image = styled.img`
  ${imageStyles};
`;

const NoImage = styled.div`
  ${imageStyles};
`;

const LinkedContent = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.Text.Default};
  text-decoration: underline;
`;

const expandable: ExpandableConfig<DataSource> = {
  expandIcon: ({ expanded, onExpand, record }) =>
    record.children ? (
      expanded ? (
        <DownOutlined onClick={(e) => onExpand(record, e)} style={{ fontSize: 10 }} />
      ) : (
        <RightOutlined onClick={(e) => onExpand(record, e)} style={{ fontSize: 10 }} />
      )
    ) : null,
};

const formatter = new Intl.NumberFormat("ja");

const { couponUsedCount, visitedTableUserCustomersCount, visitedTableUserCustomersSales } =
  couponAnalyticsProperties;

type Props = {
  isLoading: boolean;
  summaryPerCoupon: CouponAnalyticsSummaryPerCouponItem[];
};

export const PerCouponTable = memo<Props>(({ isLoading, summaryPerCoupon }) => {
  const [pagination, setPagination] = usePagination();
  const dataSource = useMemo<DataSource[]>(
    () =>
      summaryPerCoupon.map((summaryPerCoupon) => ({
        key: summaryPerCoupon.couponId,
        name: summaryPerCoupon.couponName,
        image: summaryPerCoupon.couponImageUrl ?? null,
        couponUsedCount: summaryPerCoupon.couponUsedCount,
        visitedTableUserCustomersCount: summaryPerCoupon.visitedTableUserCustomersCount,
        visitedTableUserCustomersSales: summaryPerCoupon.visitedTableUserCustomersSales,
        isShopRow: false,
        children: summaryPerCoupon.shopUsages.map((shopUsage) => ({
          key: summaryPerCoupon.couponId + "_" + shopUsage.shopId,
          name: shopUsage.shopName,
          image: null,
          couponUsedCount: shopUsage.couponUsedCount,
          visitedTableUserCustomersCount: shopUsage.visitedTableUserCustomersCount,
          visitedTableUserCustomersSales: shopUsage.visitedTableUserCustomersSales,
          isShopRow: true,
        })),
      })),
    [summaryPerCoupon],
  );

  const handleChangePagination = useCallback(
    ({ position: _, ...pagination }: TablePaginationConfig) => setPagination(pagination),
    [setPagination],
  );

  const { canAccess } = useCanAccess();

  const editable = useMemo(() => canAccess("editCoupon"), [canAccess]);

  const columns: ColumnsType<DataSource> = [
    {
      title: "",
      fixed: "left",
      width: 48,
      align: "center",
    } as const,
    {
      title: "クーポン名",
      dataIndex: "name",
      fixed: "left",
      align: "left",
      render: (value: string, { image, key, isShopRow }) => (
        <Name>
          {image ? <Image src={image} /> : <NoImage />}
          <Spacer size={18} />
          {editable && !isShopRow ? (
            <LinkedContent to={`/coupon/${key}/edit`}>{value}</LinkedContent>
          ) : (
            value
          )}
        </Name>
      ),
    },
    {
      title: <TableHeaderTitle icon={couponUsedCount.iconSrc} title={couponUsedCount.label} />,
      align: "right",
      dataIndex: "couponUsedCount",
      width: 128,
      render: (value: number) => <TableContent content={String(value)} />,
      sorter: (a, b) => a.couponUsedCount - b.couponUsedCount,
    },
    {
      title: (
        <TableHeaderTitle
          icon={visitedTableUserCustomersCount.iconSrc}
          title={visitedTableUserCustomersCount.label}
        />
      ),
      align: "right",
      dataIndex: "visitedTableUserCustomersCount",
      width: 158,
      render: (value: number) => <TableContent content={String(value)} />,
      sorter: (a, b) => a.visitedTableUserCustomersCount - b.visitedTableUserCustomersCount,
    },
    {
      title: visitedTableUserCustomersSales.label,
      fixed: "right",
      align: "right",
      dataIndex: "visitedTableUserCustomersSales",
      width: 200,
      render: (value: number) => (
        <TableContent content={`¥${formatter.format(value)}`} isHighlight />
      ),
      sorter: (a, b) => a.visitedTableUserCustomersSales - b.visitedTableUserCustomersSales,
    },
  ];

  return (
    <TableStyleModifier>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={pagination}
        onChange={handleChangePagination}
        scroll={{ x: 1136 }}
        expandable={expandable}
      />
    </TableStyleModifier>
  );
});
