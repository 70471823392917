import "libs/dayjs";

import React from "react";
import * as ReactDOMClient from "react-dom/client";

import { Root } from "components/Root";

const container = document.getElementById("root");
if (container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(<Root />);
}
