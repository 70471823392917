import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";
import { businessOperationHourTypeToWord } from "models/businessOperationHour";
import { dayOfWeeks } from "models/dayOfWeek";
import { isNotNull } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useAnalyticsSetting } from "hooks/useAnalyticsSetting";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { usePagination } from "hooks/usePagination";
import { useQueryParams } from "hooks/useQuery";
import { useSalesAnalytics } from "hooks/useSalesAnalytics";
import { ReportByType, SalesAnalyticsRow } from "hooks/useSalesAnalytics/types";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";
import { UnreachableError } from "libs/unreachable";
import { SalesAnalyticsReportingTypeType } from "types/graphql";

import pkg from "../../../package.json";

import { getColumnDefinitionsByReportingType } from "./SalesAnalyticsTable/salesAnalyticsColumns";
import { ColumnSelectModal } from "./ColumnSelectModal";
import { CsvExportModal } from "./CsvExportModal";
import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import {
  useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery,
  useSalesAnalyticsGetShopsQuery,
} from "./queries";
import { SalesAnalyticsCharts } from "./SalesAnalyticsChart";
import { SalesAnalyticsTable } from "./SalesAnalyticsTable";
import { SalesAnalyticsColumnDefinition } from "./types";

const version = pkg.version;

const salesAnalyticsSelectedColumnKey = "salesAnalyticsSelectedColumns";

const defaultReportBy = ReportByType.day;

// NOTE: dinii-dataform で未設定の場合は unknown という文字列に直している
export const salesAnalyticsBusinessOperationHourTypeLabelMap: Record<string, string> = {
  ...businessOperationHourTypeToWord,
  unknown: "未設定",
};

export const salesAnalyticsDayOfWeekLabelMap = {
  Sunday: "日",
  Monday: "月",
  Tuesday: "火",
  Wednesday: "水",
  Thursday: "木",
  Friday: "金",
  Saturday: "土",
};

const columnsByReportingType: Record<
  | ReportByType.day
  | ReportByType.month
  | ReportByType.businessOperationHourType
  | ReportByType.dayOfWeek
  | ReportByType.hour,
  SalesAnalyticsColumnDefinition[]
> = {
  [ReportByType.day]: getColumnDefinitionsByReportingType({
    reportingType: SalesAnalyticsReportingTypeType.Day,
  }),
  [ReportByType.month]: getColumnDefinitionsByReportingType({
    reportingType: SalesAnalyticsReportingTypeType.Month,
  }),
  [ReportByType.businessOperationHourType]: getColumnDefinitionsByReportingType({
    reportingType: SalesAnalyticsReportingTypeType.BusinessOperationHourType,
  }),
  [ReportByType.dayOfWeek]: getColumnDefinitionsByReportingType({
    reportingType: SalesAnalyticsReportingTypeType.DayOfWeek,
  }),
  [ReportByType.hour]: getColumnDefinitionsByReportingType({
    reportingType: SalesAnalyticsReportingTypeType.Hour,
  }),
};

type SortedColumn = {
  columnId: string;
  isEnabled: boolean;
  hideInColumnSelect: boolean;
};

const defaultSortedColumns: Record<ReportByType, SortedColumn[]> = {
  [ReportByType.day]: columnsByReportingType.day.map((column) => ({
    columnId: column.columnId,
    isEnabled: column.defaultEnabled,
    hideInColumnSelect: Boolean(column.hideInColumnSelect),
  })),
  [ReportByType.month]: columnsByReportingType.month.map((column) => ({
    columnId: column.columnId,
    isEnabled: column.defaultEnabled,
    hideInColumnSelect: Boolean(column.hideInColumnSelect),
  })),
  [ReportByType.businessOperationHourType]: columnsByReportingType.businessOperationHourType.map(
    (column) => ({
      columnId: column.columnId,
      isEnabled: column.defaultEnabled,
      hideInColumnSelect: Boolean(column.hideInColumnSelect),
    }),
  ),
  [ReportByType.dayOfWeek]: columnsByReportingType.dayOfWeek.map((column) => ({
    columnId: column.columnId,
    isEnabled: column.defaultEnabled,
    hideInColumnSelect: Boolean(column.hideInColumnSelect),
  })),
  [ReportByType.hour]: columnsByReportingType.hour.map((column) => ({
    columnId: column.columnId,
    isEnabled: column.defaultEnabled,
    hideInColumnSelect: Boolean(column.hideInColumnSelect),
  })),
};

/**
 * カラム追加/削除時にブラウザに保存済みの表示対象カラムとコンフリクトしないようにマージする関数
 */
const getInitialSortedColumns = () => {
  const savedColumns = localStorage.getItem(salesAnalyticsSelectedColumnKey);

  if (!savedColumns) return defaultSortedColumns;

  const sortedColumns: Record<ReportByType, SortedColumn[]> = JSON.parse(savedColumns);

  const initSortedColumns = (type: ReportByType) => {
    const sortedColumnsByType = sortedColumns[type];

    if (!sortedColumnsByType) {
      return defaultSortedColumns[type];
    }

    const columnIds = sortedColumns[type].map(({ columnId }) => columnId);
    const defaultColumnIds = defaultSortedColumns[type].map(({ columnId }) => columnId);

    return [
      ...sortedColumns[type].filter(({ columnId }) => defaultColumnIds.includes(columnId)),
      ...defaultSortedColumns[type].filter(({ columnId }) => !columnIds.includes(columnId)),
    ];
  };

  return {
    [ReportByType.day]: initSortedColumns(ReportByType.day),
    [ReportByType.month]: initSortedColumns(ReportByType.month),
    [ReportByType.businessOperationHourType]: initSortedColumns(
      ReportByType.businessOperationHourType,
    ),
    [ReportByType.dayOfWeek]: initSortedColumns(ReportByType.dayOfWeek),
    [ReportByType.hour]: initSortedColumns(ReportByType.hour),
  };
};

const getFileName = ({
  reportByType,
  startDate,
  endDate,
}: {
  reportByType: ReportByType;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}) => {
  const getReportByTypeLabel = (reportByType: ReportByType) => {
    switch (reportByType) {
      case ReportByType.day:
        return "日別";
      case ReportByType.month:
        return "月別";
      case ReportByType.businessOperationHourType:
        return "時間帯別";
      case ReportByType.dayOfWeek:
        return "曜日別";
      case ReportByType.hour:
        return "時間別";

      default:
        throw new UnreachableError(reportByType);
    }
  };

  const reportByTypeLabel = getReportByTypeLabel(reportByType);

  return `売上分析_${reportByTypeLabel}_${startDate.format("YYYYMMDD")}-${endDate.format(
    "YYYYMMDD",
  )}`;
};

const TopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FiltersWrapper = styled.div`
  flex-grow: 1;
`;

export const SalesAnalytics = () => {
  const navigate = useNavigate();

  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId ?? null;

  const { shop, setShop: setShopId } = useShopPerCorporation();

  const query = useQueryParams();
  // NOTE: 店舗別売上一覧ページのリンクから shopId クエリパラメタとともに遷移してくる場合がある
  const queryParamsShopId = query.get("shopId");

  useEffect(() => {
    if (queryParamsShopId) {
      setShopId(queryParamsShopId);
    }
  }, [queryParamsShopId, setShopId]);

  const [pagination, setPagination] = usePagination();
  const [showColumnSelectModal, setShowColumnSelectModal] = useState(false);
  const [showCsvExportModal, setShowCsvExportModal] = useState(false);
  const [sortedColumns, setSortedColumns] = useState<Record<ReportByType, SortedColumn[]>>(
    getInitialSortedColumns(),
  );
  const [editingSortedColumns, setEditingSortedColumns] = useState<Record<
    ReportByType,
    SortedColumn[]
  > | null>(null);

  const {
    analyticsSetting: { displayTaxIncluded },
  } = useAnalyticsSetting({ corporationId });

  const {
    data: getShopsData,
    error: getShopsError,
    loading: loadingGetShops,
  } = useSalesAnalyticsGetShopsQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      businessOperationHourTypes: [],
      range: [dayjs().startOf("month"), dayjs()],
      reportByType: defaultReportBy,
    },
    undefined,
    {
      serialize: ({ businessOperationHourTypes, range, reportByType }) => ({
        businessOperationHourTypes: businessOperationHourTypes ?? [],
        range: serializeRange(range),
        reportByType: reportByType ?? defaultReportBy,
      }),
      deserialize: ({ businessOperationHourTypes, range, reportByType }) => ({
        businessOperationHourTypes,
        range: deserializeRange(range),
        reportByType,
      }),
    },
  );

  const columnsWithEnabledStatus = useMemo(
    () =>
      sortedColumns[filterConditions.reportByType ?? defaultReportBy]
        .map(({ columnId, isEnabled }) => {
          const referenceColumn = columnsByReportingType[
            filterConditions.reportByType ?? defaultReportBy
          ].find((column) => column.columnId === columnId);

          if (!referenceColumn) return null;

          return {
            ...referenceColumn,
            isEnabled,
          };
        })
        .filter(isNotNull),
    [filterConditions.reportByType, sortedColumns],
  );

  const editingColumnsWithEnabledStatus = useMemo(
    () =>
      editingSortedColumns
        ? editingSortedColumns[filterConditions.reportByType ?? defaultReportBy]
            .map(({ columnId, isEnabled }) => {
              const referenceColumn = columnsByReportingType[
                filterConditions.reportByType ?? defaultReportBy
              ].find((column) => column.columnId === columnId);

              if (!referenceColumn) return null;

              return {
                ...referenceColumn,
                isEnabled,
              };
            })
            .filter(isNotNull)
        : [],
    [editingSortedColumns, filterConditions.reportByType],
  );

  const { data: businessOperationHourTypesData, error: entitiesError } =
    useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery(
      shop?.shopId
        ? {
            variables: {
              shopId: shop.shopId,
            },
          }
        : { skip: true },
    );

  const businessOperationHourTypes =
    businessOperationHourTypesData?.shopBusinessOperationHour ?? [];

  // const businessOperationHourTypesToSortIndex = useMemo(
  //   () =>
  //     new Map(
  //       (businessOperationHourTypes?.shopBusinessOperationHour ?? [])
  //         .sort((a, b) => (dayjs(a.start).isAfter(dayjs(b.start)) ? 1 : -1))
  //         .map((shopBusinessOperationHour, ind) => [
  //           shopBusinessOperationHour.businessOperationHourType,
  //           ind,
  //         ]),
  //     ),
  //   [businessOperationHourTypes],
  // );

  const { normalizedRows, exportSalesAnalyticsCsv, isLoading } = useSalesAnalytics({
    shopId: shop?.shopId ?? null,
    selectedBusinessOperationHourTypes: filterConditions.businessOperationHourTypes ?? [],
    showTaxIncluded: displayTaxIncluded,
    startDate: filterConditions.range?.[0] ?? dayjs().subtract(30, "days"),
    endDate: filterConditions.range?.[1] ?? dayjs(),
    reportByType: filterConditions.reportByType ?? ReportByType.month,
    businessOperationHourTypes,
  });

  const sortedSalesAnalytics: SalesAnalyticsRow[] = useMemo(
    () =>
      normalizedRows.sort((a, b) => {
        // NOTE: サマリは常に先頭に表示する
        if (!a.isEmpty && a.isSummaryRow) return -1;
        if (!b.isEmpty && b.isSummaryRow) return 1;

        if (filterConditions.reportByType === ReportByType.businessOperationHourType) {
          if (!a.businessOperationHourLabel) return 1;
          if (!b.businessOperationHourLabel) return -1;
          return a.businessOperationHourLabel.localeCompare(b.businessOperationHourLabel);
        }

        if (filterConditions.reportByType === ReportByType.dayOfWeek) {
          const ai = dayOfWeeks.indexOf(a.name);
          const bi = dayOfWeeks.indexOf(b.name);

          return ai - bi;
        }

        if (filterConditions.reportByType === ReportByType.hour) {
          const hourA = Number(a.name);
          const hourB = Number(b.name);

          if (hourA >= 6 && hourB < 6) return -1;
          if (hourA < 6 && hourB >= 6) return 1;

          return Number(a.name) < Number(b.name) ? -1 : 1;
        }

        return dayjs(a.name).isAfter(dayjs(b.name)) ? 1 : -1;
      }),
    [filterConditions.reportByType, normalizedRows],
  );

  const handleEditColumns = useCallback(() => {
    setEditingSortedColumns(sortedColumns);
    setShowColumnSelectModal(true);
  }, [sortedColumns]);
  const handleToggleColumn = useCallback(
    (columnId: string) => {
      if (editingSortedColumns === null) return;

      const reportByType = filterConditions.reportByType ?? defaultReportBy;

      const newIds = editingSortedColumns[reportByType].map((column) =>
        column.columnId === columnId ? { ...column, isEnabled: !column.isEnabled } : column,
      );

      setEditingSortedColumns((columnIds) =>
        columnIds ? { ...columnIds, [reportByType]: newIds } : columnIds,
      );
    },
    [editingSortedColumns, filterConditions.reportByType],
  );
  const handleMoveColumns = useCallback(
    ({ dragIndex, hoverIndex }: { dragIndex: number; hoverIndex: number }) => {
      if (editingSortedColumns === null) return;

      const reportByType = filterConditions.reportByType ?? defaultReportBy;

      // the column that we are dragging
      const dragIndexColumn = editingSortedColumns[reportByType].filter(
        (column) => !column.hideInColumnSelect,
      )[dragIndex];

      // the column that we dropped onto
      const afterIndexColumn = editingSortedColumns[reportByType].filter(
        (column) => !column.hideInColumnSelect,
      )[hoverIndex];

      if (!dragIndexColumn) return;

      if (dragIndex === hoverIndex) return;

      setEditingSortedColumns((value) => {
        if (value === null) return value;

        const columns = value[reportByType];

        // remove the column that we dragged
        const filteredColumns = columns.filter(
          ({ columnId }) => columnId !== dragIndexColumn.columnId,
        );
        // this is where we will insert the column that we dragged
        const newAfterIndex = filteredColumns.findIndex(
          ({ columnId }) => columnId === afterIndexColumn?.columnId,
        );

        // if the dragged column is moved down, we put it AFTER the dropped column
        // if the dragged column is moved up, we put it BEFORE the dropped column
        const beforeOrAfterOffset = dragIndex < hoverIndex ? 1 : 0;

        const sortedColumns = [
          ...filteredColumns.slice(0, newAfterIndex + beforeOrAfterOffset),
          dragIndexColumn,
          ...filteredColumns.slice(newAfterIndex + beforeOrAfterOffset, filteredColumns.length),
        ];

        return {
          ...value,
          [reportByType]: [...sortedColumns],
        };
      });
    },
    [editingSortedColumns, filterConditions.reportByType],
  );
  const handleSaveColumns = useCallback(() => {
    if (!editingSortedColumns) return;

    setSortedColumns(editingSortedColumns);

    localStorage.setItem(
      salesAnalyticsSelectedColumnKey,
      JSON.stringify({ ...editingSortedColumns, version }),
    );

    setEditingSortedColumns(null);

    setShowColumnSelectModal(false);
  }, [editingSortedColumns]);
  const handleCloseEditColumnsModal = useCallback(() => setShowColumnSelectModal(false), []);

  const handleOpenCsvExportModal = useCallback(() => setShowCsvExportModal(true), []);
  const handleExportCsv = useCallback(
    ({ shopIds }: { shopIds: string[] }) => {
      if (!filterConditions.range?.[0] || !filterConditions.range?.[1]) return;

      const reportByType = filterConditions.reportByType ?? defaultReportBy;
      const [startDate, endDate] = filterConditions.range;

      const today = dayjs().startOf("day");
      const lastDateOfEndDateMonth = endDate.endOf("month");

      const fileName = getFileName({
        reportByType,
        startDate,
        // NOTE: ReportByType.month は range を月初日で持っているので、ファイル名には月末日に変換して使用する
        endDate:
          reportByType === ReportByType.month
            ? lastDateOfEndDateMonth.isAfter(today)
              ? today
              : lastDateOfEndDateMonth
            : endDate,
      });

      exportSalesAnalyticsCsv({
        fileName,
        columnsWithEnabledStatus,
        reportByType,
        shopIds,
      });
    },
    [
      filterConditions.range,
      filterConditions.reportByType,
      exportSalesAnalyticsCsv,
      columnsWithEnabledStatus,
    ],
  );
  const handleCloseCsvExportModal = useCallback(() => setShowCsvExportModal(false), []);

  // NOTE: 店舗選択時にクエリパラメタを削除して正しくデータが反映されるようにする
  const handleChangeShop = useCallback(() => {
    navigate("/sales-analytics", { replace: true });
  }, [navigate]);

  return (
    <DashboardLayout title="売上分析（β版）">
      <PageHeader title="売上分析（β版）" footer={null} />
      <Spacer size={12} />
      <TopBar>
        <FiltersWrapper>
          <Filters
            filterConditions={{
              businessOperationHourTypes: filterConditions.businessOperationHourTypes ?? [],
              range: filterConditions.range,
              reportByType: filterConditions.reportByType ?? defaultReportBy,
            }}
            onChangeFilterCondition={updateFilterCondition}
            onShopChange={handleChangeShop}
            onEditColumns={handleEditColumns}
            onPressCsvExport={handleOpenCsvExportModal}
          />
        </FiltersWrapper>
      </TopBar>

      {entitiesError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <SalesAnalyticsCharts
        rows={sortedSalesAnalytics}
        reportByType={filterConditions.reportByType ?? defaultReportBy}
      />

      <Spacer size={16} />

      <SalesAnalyticsTable
        columnsWithEnabledState={columnsWithEnabledStatus}
        rows={sortedSalesAnalytics}
        isLoading={isLoading}
        reportByType={filterConditions.reportByType ?? defaultReportBy}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      <ColumnSelectModal
        columns={editingColumnsWithEnabledStatus}
        isOpen={showColumnSelectModal}
        onSave={handleSaveColumns}
        onMove={handleMoveColumns}
        onCancel={handleCloseEditColumnsModal}
        onToggleColumn={handleToggleColumn}
      />

      <CsvExportModal
        isOpen={showCsvExportModal}
        companies={companies}
        onDownload={handleExportCsv}
        onClose={handleCloseCsvExportModal}
      />
    </DashboardLayout>
  );
};
