import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  CompanySalesAnalyticsRow,
  NormalizedCompanySalesAnalyticsRow,
} from "hooks/useCompanySalesAnalytics/types";

import { CompanySalesAnalyticsColumnDefinition } from "../types";

const formatter = new Intl.NumberFormat("ja");

const getFormattedPercentage = (percentage: number | null) =>
  percentage === null
    ? "-"
    : !isFinite(percentage)
    ? "0.0%"
    : new Intl.NumberFormat("default", {
        style: "percent",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(percentage);

const getFormattedMonetaryAmount = (amount: number | null) =>
  amount === null ? "-" : !isFinite(amount) ? "¥0" : `¥${formatter.format(Number(amount))}`;

const StyledColumnTitle = styled.div`
  text-align: center;
  width: 100%;
`;

const RowText = styled.div<{ isSummary: boolean }>`
  font-weight: ${({ isSummary }) => (isSummary ? "bold" : "normal")};
`;

const NameContainer = styled.div<{ isSummary: boolean }>`
  text-align: left;
  font-weight: ${({ isSummary }) => (isSummary ? "bold" : "normal")};
`;

type GoalProps =
  | { percentage: number; difference?: never; isSummary: boolean }
  | { difference: number; percentage?: never; isSummary: boolean };

const GoalPercentage = styled.div<GoalProps>`
  color: ${({ percentage, difference }) =>
    percentage !== undefined
      ? isFinite(percentage)
        ? percentage >= 1
          ? "#52C41A"
          : "#FF4D4F "
        : "unset"
      : isFinite(difference)
      ? difference >= 0
        ? "#52C41A"
        : "#FF4D4F "
      : "unset"};
  font-weight: ${({ isSummary }) => (isSummary ? "bold" : "normal")};
`;

export const getColumnDefinitions: () => CompanySalesAnalyticsColumnDefinition[] = () => {
  const preColumns: (Omit<CompanySalesAnalyticsColumnDefinition, "render"> & {
    render?: (
      value: unknown,
      record: NormalizedCompanySalesAnalyticsRow,
      index: number,
    ) => React.ReactNode;
  })[] = [
    {
      label: "店舗名",
      description: ``,
      defaultEnabled: true,
      hideInColumnSelect: true,
      columnId: "shopName",
      width: 130,
      render: (_, { shopName, isSummaryRow, shopId }) =>
        !isSummaryRow ? (
          <Link to={`/sales-analytics?shopId=${shopId}`}>
            <NameContainer isSummary={isSummaryRow}>{shopName}</NameContainer>
          </Link>
        ) : (
          <NameContainer isSummary={isSummaryRow}>{shopName}</NameContainer>
        ),
    },
    {
      label: "営業日数",
      description: `以下の日数を営業日として集計します。
・昨日以前：精算が行われた日数
・今日以降：売上目標が設定されている日数（売上目標を設定していない場合は全ての日数）
      `,
      defaultEnabled: false,
      columnId: "businessDaysCount",
      render: (_, { businessDaysCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{businessDaysCount}</RowText>
      ),
    },
    {
      label: "売上",
      description: `値引後売上です。`,
      defaultEnabled: true,
      columnId: "totalAmount",
      render: (_, { totalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(totalAmount)}</RowText>
      ),
    },
    {
      label: "売上目標",
      description: `売上目標です。
本部機能 > 目標管理 から設定できます。`,
      defaultEnabled: true,
      columnId: "salesTargetAmount",
      render: (_, { salesTargetAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {salesTargetAmount ? getFormattedMonetaryAmount(salesTargetAmount) : "-"}
        </RowText>
      ),
    },
    {
      label: "目標達成率",
      description: `「売上 ÷ 売上目標」です。`,
      defaultEnabled: true,
      columnId: "goalCompletionPercentage",
      render: (_, { goalCompletionPercentage, isSummaryRow }) =>
        goalCompletionPercentage !== null ? (
          <GoalPercentage percentage={goalCompletionPercentage} isSummary={isSummaryRow}>
            {getFormattedPercentage(goalCompletionPercentage)}
          </GoalPercentage>
        ) : (
          "-"
        ),
    },
    {
      label: "売上目標差分",
      description: `「売上 - 売上目標」です。`,
      defaultEnabled: true,
      width: 130,
      columnId: "goalDifference",
      render: (_, { totalAmount, salesTargetAmount, isSummaryRow }) =>
        salesTargetAmount ? (
          <GoalPercentage difference={totalAmount - salesTargetAmount} isSummary={isSummaryRow}>
            {`¥${totalAmount - salesTargetAmount >= 0 ? "+" : ""}${formatter.format(
              totalAmount - salesTargetAmount,
            )}`}
          </GoalPercentage>
        ) : (
          "-"
        ),
    },
    /* TODO: 定義を fix して実装する
    {
      label: "前年比",
      description: `「売上 ÷ 前年同月の同曜日の平均売上」です。`,
      defaultEnabled: true,
      columnId: "previousYearComparisonPercentage",
      render: (_, { totalAmount, previousYearTotalAmount }) => (
        <GoalPercentage percentage={totalAmount / previousYearTotalAmount}>
          {getFormattedPercentage(totalAmount / previousYearTotalAmount)}
        </GoalPercentage>
      ),
    },
    {
      label: "前月比",
      description: `「売上 ÷ 前年の売上」です。`,
      defaultEnabled: false,
      columnId: "previousMonthTotalAmount",
      render: (_, { totalAmount, previousMonthTotalAmount }) => (
        <GoalPercentage percentage={totalAmount / previousMonthTotalAmount}>
          {getFormattedPercentage(totalAmount / previousMonthTotalAmount)}
        </GoalPercentage>
      ),
    },
    {
      label: "前月同曜日比",
      description: `「売上 ÷ 前月の同曜日の平均売上」です。`,
      defaultEnabled: false,
      columnId: "previousMonthSameWeekDayPercentage",
      width: 120,
    },*/
    {
      label: "リピーター売上",
      description: `リピーターがいた食事の売上です。`,
      defaultEnabled: false,
      columnId: "repeaterTableTotalAmount",
      width: 130,
      render: (_, { repeaterTableTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(
            repeaterTableTotalAmount ? Number(repeaterTableTotalAmount) : 0,
          )}
        </RowText>
      ),
    },
    {
      label: "リピーター売上比率",
      description: `「リピーター売上 ÷ 売上」です。`,
      defaultEnabled: false,
      columnId: "repeaterSalesPercentage",
      width: 160,
      render: (_, { repeaterTableTotalAmount, totalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedPercentage(repeaterTableTotalAmount / totalAmount)}
        </RowText>
      ),
    },
    {
      label: "原価",
      description: `商品の原価の合計です。`,
      defaultEnabled: false,
      columnId: "totalCostAmount",
      render: (_, { totalCostAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(Number(totalCostAmount))}
        </RowText>
      ),
    },
    {
      label: "原価率",
      description: `「原価 ÷ 売上」です。`,
      defaultEnabled: false,
      columnId: "costPercentage",
      render: (_, { totalAmount, totalCostAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedPercentage(totalCostAmount ? totalCostAmount / totalAmount : null)}
        </RowText>
      ),
    },
    {
      label: "粗利",
      description: `「売上 - 原価」です。`,
      defaultEnabled: false,
      columnId: "grossProfitAmount",
      render: (_, { grossProfitAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(grossProfitAmount)}</RowText>
      ),
    },
    {
      label: "粗利率",
      description: `「粗利 ÷ 売上」です。`,
      defaultEnabled: false,
      columnId: "grossProfitPercentage",
      render: (_, { grossProfitPercentage, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedPercentage(grossProfitPercentage)}</RowText>
      ),
    },
    {
      label: "組数",
      description: `組数です。
グルーピング時は1組、合算会計時は複数組となります。`,
      defaultEnabled: false,
      columnId: "groupCount",
      render: (_, { groupCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{groupCount}</RowText>
      ),
    },
    {
      label: "チェックイン組数",
      description: `LINEチェックインしたお客様がいた組数です。`,
      defaultEnabled: false,
      columnId: "checkedInGroupCount",
      width: 150,
      render: (_, { checkedInGroupCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{checkedInGroupCount}</RowText>
      ),
    },
    {
      label: "組チェックイン率",
      description: `「チェックイン組数 ÷ 組数」です。`,
      defaultEnabled: false,
      columnId: "groupCheckInPercentage",
      width: 150,
      render: (_, { groupCheckInPercentage, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedPercentage(groupCheckInPercentage)}</RowText>
      ),
    },
    {
      label: "客数",
      description: `スタッフのハンディ・レジ、お客様のモバイルオーダーで入力された来店人数です。`,
      defaultEnabled: true,
      columnId: "numPeople",
      width: 150,
      render: (_, { numPeople, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(numPeople)}</RowText>
      ),
    },
    {
      label: "チェックイン客数",
      description: `LINE チェックインしたお客様数です。`,
      defaultEnabled: false,
      columnId: "customerCount",
      width: 150,
      render: (_, { customerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(customerCount)}</RowText>
      ),
    },
    {
      label: "客チェックイン率",
      description: `「チェックイン客数 ÷ 客数」です。`,
      defaultEnabled: true,
      columnId: "customerCheckInPercentage",
      width: 150,
      render: (_, { numPeople, customerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedPercentage(customerCount / numPeople)}
        </RowText>
      ),
    },
    {
      label: "未チェックイン客数",
      description: `「客数 - チェックイン客数」です。`,
      defaultEnabled: false,
      columnId: "notCheckedInNumPeople",
      width: 150,
      render: (_, { notCheckedInNumPeople, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(notCheckedInNumPeople)}</RowText>
      ),
    },
    {
      label: "新規客数",
      description: `業態内の店舗に初来店のお客様数です。
LINEチェックインで判定しています。`,
      defaultEnabled: false,
      columnId: "newCustomerCount",
      render: (_, { newCustomerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(newCustomerCount)}</RowText>
      ),
    },
    {
      label: "リピーター数",
      description: `業態内の店舗に2回目以降の来店のお客様数です。
LINEチェックインで判定しています。`,
      defaultEnabled: false,
      columnId: "repeatVisitCustomerCount",
      dataIndex: "repeatVisitCustomerCount",
      width: 150,
      render: (_, { repeatVisitCustomerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{repeatVisitCustomerCount}</RowText>
      ),
    },
    {
      label: "リピーター比率",
      description: `「リピーター数 ÷ 客数」です。`,
      defaultEnabled: true,
      columnId: "repeaterPercentage",
      width: 150,
      render: (_, { repeaterPercentage, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedPercentage(repeaterPercentage)}</RowText>
      ),
    },
    {
      label: "アンバサダー数",
      description: `新規客と一緒に来店されたリピーター数。
LINEチェックインで判定しています。`,
      defaultEnabled: false,
      columnId: "ambassadorCount",
      width: 150,
      render: (_, { ambassadorCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(ambassadorCount)}</RowText>
      ),
    },
    {
      label: "紹介新規客数",
      description: `アンバサダーと一緒に来店された新規客数。
LINEチェックインで判定しています。`,
      defaultEnabled: false,
      columnId: "introducedCustomerCount",
      width: 130,
      render: (_, { introducedCustomerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(introducedCustomerCount)}</RowText>
      ),
    },
    {
      label: "客単価",
      description: `「売上 ÷ 客数」です。`,
      defaultEnabled: true,
      columnId: "salesPerCustomer",
      render: (_, { salesPerCustomer, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(salesPerCustomer)}</RowText>
      ),
    },
    {
      label: "ランチ売上",
      description: `ランチ時間帯 (モーニング, ランチ, カフェ) での売上です。
時間帯は店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 から設定できます。`,
      defaultEnabled: false,
      columnId: "lunchTotalAmount",
      render: (_, { lunchTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(lunchTotalAmount)}</RowText>
      ),
    },
    {
      label: "ランチ客数",
      description: `ランチ時間帯 (モーニング, ランチ, カフェ) での客数です。
時間帯は店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 から設定できます。`,
      defaultEnabled: false,
      columnId: "lunchCustomerCount",
      render: (_, { lunchCustomerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(lunchCustomerCount)}</RowText>
      ),
    },
    {
      label: "ランチ客単価",
      description: `ランチ時間帯 (モーニング, ランチ, カフェ) での客単価です。
時間帯は店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 から設定できます。`,
      defaultEnabled: false,
      columnId: "lunchSalesPerCustomer",
      width: 120,
      render: (_, { lunchSalesPerCustomer, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(lunchSalesPerCustomer)}
        </RowText>
      ),
    },
    {
      label: "ディナー売上",
      description: `ディナー時間帯 (ハッピーアワー, ディナー, 深夜, 未設定) での売上です。
時間帯は店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 から設定できます。`,
      defaultEnabled: false,
      columnId: "dinnerTotalAmount",
      width: 120,
      render: (_, { dinnerTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(Number(dinnerTotalAmount))}
        </RowText>
      ),
    },
    {
      label: "ディナー客数",
      description: `ディナー時間帯 (ハッピーアワー, ディナー, 深夜, 未設定) での客数です。
時間帯は店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 から設定できます。`,
      defaultEnabled: false,
      width: 140,
      columnId: "dinnerCustomerCount",
      render: (_, { dinnerCustomerCount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(dinnerCustomerCount)}</RowText>
      ),
    },
    {
      label: "ディナー客単価",
      description: `ディナー時間帯 (ハッピーアワー, ディナー, 深夜, 未設定) での客単価です。
時間帯は店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 から設定できます。`,
      defaultEnabled: false,
      columnId: "dinnerSalesPerCustomer",
      width: 140,
      render: (_, { dinnerSalesPerCustomer, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(dinnerSalesPerCustomer)}
        </RowText>
      ),
    },
    {
      label: "店内売上",
      description: `店内利用での売上です。`,
      defaultEnabled: false,
      columnId: "totalEatInAmount",
      render: (_, { totalEatInAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(totalEatInAmount)}</RowText>
      ),
    },
    {
      label: "店内客数",
      description: `店内利用での客数です。`,
      defaultEnabled: false,
      columnId: "eatInNumPeople",
      render: (_, { eatInNumPeople, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(eatInNumPeople)}</RowText>
      ),
    },
    {
      label: "店内客単価",
      description: `店内利用での客単価です。`,
      defaultEnabled: false,
      columnId: "eatInSalesPerCustomer",
      render: (_, { eatInSalesPerCustomer, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(eatInSalesPerCustomer)}
        </RowText>
      ),
    },
    {
      label: "店外売上",
      description: `店外利用での売上です。
店外メニューとして設定したメニューのみで構成された食事が対象です。`,
      defaultEnabled: false,
      columnId: "totalTakeOutAmount",
      render: (_, { totalTakeOutAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(totalTakeOutAmount)}</RowText>
      ),
    },
    {
      label: "店外客数",
      description: `店外利用での客数です。
店外メニューとして設定したメニューのみで構成された食事が対象です。`,
      defaultEnabled: false,
      columnId: "takeOutNumPeople",
      render: (_, { takeOutNumPeople, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{formatter.format(takeOutNumPeople)}</RowText>
      ),
    },
    {
      label: "店外客単価",
      description: `店外利用での客単価です。
店外メニューとして設定したメニューのみで構成された食事が対象です。`,
      defaultEnabled: false,
      columnId: "takeOutSalesPerCustomer",
      render: (_, { takeOutSalesPerCustomer, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(takeOutSalesPerCustomer)}
        </RowText>
      ),
    },
    {
      label: "プラン売上",
      description: `プランの売上です。`,
      defaultEnabled: false,
      columnId: "planTotalAmount",
      render: (_, { planTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(planTotalAmount)}</RowText>
      ),
    },
    {
      label: "フード売上",
      description: `メニュータイプを「フード」に設定しているメニューの売上です。`,
      defaultEnabled: false,
      columnId: "foodTotalAmount",
      render: (_, { foodTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(foodTotalAmount)}</RowText>
      ),
    },
    {
      label: "ドリンク売上",
      description: `メニュータイプを「ドリンク」に設定しているメニューの売上です。`,
      defaultEnabled: false,
      columnId: "drinkTotalAmount",
      width: 120,
      render: (_, { drinkTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(drinkTotalAmount)}</RowText>
      ),
    },
    {
      label: "推しエール売上",
      description: `推しエールの売上です。`,
      defaultEnabled: false,
      columnId: "faveYellTotalAmount",
      width: 140,
      render: (_, { faveYellTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>
          {getFormattedMonetaryAmount(faveYellTotalAmount)}
        </RowText>
      ),
    },
    {
      label: "その他売上",
      description: `メニュータイプを「その他」に設定している、もしくは未設定のメニューの売上です。`,
      defaultEnabled: false,
      columnId: "otherTotalAmount",
      render: (_, { otherTotalAmount, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedMonetaryAmount(otherTotalAmount)}</RowText>
      ),
    },
    {
      label: "モバイルオーダー率",
      description: `「モバイルオーダーからの注文回数 ÷ 注文回数」です。`,
      defaultEnabled: false,
      columnId: "mobileOrderPercentage",
      width: 150,
      render: (_, { mobileOrderPercentage, isSummaryRow }) => (
        <RowText isSummary={isSummaryRow}>{getFormattedPercentage(mobileOrderPercentage)}</RowText>
      ),
    },
    /*
    TODO: implement
    {
      label: "予約組数",
      description: `予約台帳連携で予約登録された組数です。`,
      defaultEnabled: false,
      columnId: "reservationGroupCount",
    },
    {
      label: "予約客数",
      description: `予約台帳連携で予約登録された組の客数です。`,
      defaultEnabled: false,
      columnId: "reservationCustomerCount",
    },
    {
      label: "予約売上",
      description: `予約台帳連携で予約登録された組の売上です。`,
      defaultEnabled: false,
      columnId: "reservationTotalAmount",
      //render: (_, { reservationTotalAmount }) => getFormattedMonetaryAmount(reservationTotalAmount),
    },*/
  ];

  return preColumns.map((column) => ({
    ...column,
    width: column.width === undefined ? 100 : column.width,
    align: "right",
    title: <StyledColumnTitle>{column.label}</StyledColumnTitle>,
    render: (value: any, record: CompanySalesAnalyticsRow, index: number) =>
      !record.isEmpty ? column.render?.(value, record, index) : "-",
  }));
};
