import React, { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ExpandableConfig } from "antd/lib/table/interface";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { messageDeliveryAnalyticsProperties } from "models/messageDeliveryAnalytics";

import { TableContent } from "components/AnalyticsTable/TableContent";
import { TableErrorContent } from "components/AnalyticsTable/TableErrorContent";
import { TableHeaderTitle } from "components/AnalyticsTable/TableHeaderTitle";
import { TableStyleModifier } from "components/AnalyticsTable/TableStyleModifier";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { colors } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import {
  MessageDeliveryAnalyticsSummaryPerMessageDelivery,
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem,
} from "../type";

type CommonSource = {
  key: string;
  type: "messageDelivery" | "shop";
  isNotMessageOpenCountCollected: boolean;
  name: string;
  image: string | null;
  lastMessageDeliveryJobId: string;
  startAt: string;
  endAt: string;
} & Pick<
  MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem,
  | "messageSentCount"
  | "messageOpenCount"
  | "visitedCustomersCount"
  | "visitedTableUserCustomersCount"
  | "visitedTableUserCustomersSales"
>;

type DataSource = CommonSource & {
  children: (CommonSource & { children: null })[] | null;
};

const Name = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const imageStyles = css`
  width: 64px;
  height: 64px;
`;

const Image = styled.img`
  ${imageStyles};
`;

const NoImage = styled.div`
  ${imageStyles};
`;

const LinkedContent = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.Text.Default};
  text-decoration: underline;
`;

const formatter = new Intl.NumberFormat("ja");

const {
  messageSentCount,
  messageOpenCount,
  visitedCustomersCount,
  visitedTableUserCustomersCount,
  visitedTableUserCustomersSales,
} = messageDeliveryAnalyticsProperties;

const createColumns = ({ editable }: { editable: boolean }): ColumnsType<DataSource> => [
  {
    title: "",
    fixed: "left",
    width: 48,
    align: "center",
  } as const,
  {
    title: "配信管理名",
    dataIndex: "name",
    fixed: "left",
    align: "left",
    render(value: string, { type, image, lastMessageDeliveryJobId }) {
      return (
        <Name>
          {image ? <Image src={image} /> : <NoImage />}
          <Spacer size={image || type === "messageDelivery" ? 18 : 36} />
          {type === "messageDelivery" && editable ? (
            <LinkedContent to={`/messageDelivery/job/${lastMessageDeliveryJobId}`}>
              {value}
            </LinkedContent>
          ) : (
            value
          )}
        </Name>
      );
    },
  },
  {
    title: "配信期間",
    align: "left",
    width: 144,
    render(_, { startAt, endAt }) {
      return (
        <TableContent
          isLight
          content={
            dayjs(startAt).format("YYYY/MM/DD") + " 〜\n" + dayjs(endAt).format("YYYY/MM/DD")
          }
        />
      );
    },
  },
  {
    title: <TableHeaderTitle icon={messageSentCount.iconSrc} title={messageSentCount.label} />,
    align: "right",
    dataIndex: "messageSentCount",
    width: 128,
    render(value: number) {
      return <TableContent content={String(value)} subContent="100%" />;
    },
    sorter: (a, b) => a.messageSentCount - b.messageSentCount,
  },
  {
    title: <TableHeaderTitle icon={messageOpenCount.iconSrc} title={messageOpenCount.label} />,
    align: "right",
    dataIndex: "messageOpenCount",
    width: 128,
    render(
      value: MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem["messageOpenCount"],
      { messageSentCount, isNotMessageOpenCountCollected },
    ) {
      if (isNotMessageOpenCountCollected || !value) {
        return (
          <TableErrorContent
            message={
              isNotMessageOpenCountCollected
                ? "2023年2月以前のデータは表示されません"
                : "開封数が20未満の場合、表示されません"
            }
          />
        );
      }

      return (
        <TableContent
          content={String(value)}
          subContent={`${
            messageSentCount === 0 ? 0 : Math.round((value / messageSentCount) * 100 * 10) / 10
          }%`}
        />
      );
    },
    sorter: (a, b) => (a.messageOpenCount ?? 0) - (b.messageOpenCount ?? 0),
  },
  {
    title: (
      <TableHeaderTitle icon={visitedCustomersCount.iconSrc} title={visitedCustomersCount.label} />
    ),
    align: "right",
    dataIndex: "visitedCustomersCount",
    width: 128,
    render(value: number, { messageSentCount }) {
      return (
        <TableContent
          content={String(value)}
          subContent={`${
            messageSentCount === 0 ? 0 : Math.round((value / messageSentCount) * 100 * 10) / 10
          }%`}
        />
      );
    },
    sorter: (a, b) => a.visitedCustomersCount - b.visitedCustomersCount,
  },
  {
    title: (
      <TableHeaderTitle
        icon={visitedTableUserCustomersCount.iconSrc}
        title={visitedTableUserCustomersCount.label}
      />
    ),
    align: "right",
    dataIndex: "visitedTableUserCustomersCount",
    width: 158,
    render(value: number) {
      return <TableContent content={String(value)} />;
    },
    sorter: (a, b) => a.visitedTableUserCustomersCount - b.visitedTableUserCustomersCount,
  },
  {
    title: visitedTableUserCustomersSales.label,
    fixed: "right",
    align: "right",
    dataIndex: "visitedTableUserCustomersSales",
    width: 158,
    render(value: number) {
      return <TableContent content={`¥${formatter.format(value)}`} isHighlight />;
    },
    sorter: (a, b) => a.visitedTableUserCustomersSales - b.visitedTableUserCustomersSales,
  },
];

const expandable: ExpandableConfig<DataSource> = {
  expandIcon: ({ expanded, onExpand, record }) =>
    record.children === null ? null : expanded ? (
      <DownOutlined onClick={(e) => onExpand(record, e)} style={{ fontSize: 10 }} />
    ) : (
      <RightOutlined onClick={(e) => onExpand(record, e)} style={{ fontSize: 10 }} />
    ),
};

type Props = {
  summaryPerMessageDelivery: MessageDeliveryAnalyticsSummaryPerMessageDelivery | undefined;
  isNotMessageOpenCountCollected: boolean;
};

export const SummaryPerMessageDeliveryTable = memo<Props>(
  ({ summaryPerMessageDelivery, isNotMessageOpenCountCollected }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();

    const dataSource = useMemo<DataSource[]>(
      () =>
        !summaryPerMessageDelivery
          ? []
          : summaryPerMessageDelivery.items.map(
              (
                {
                  messageDeliveryName,
                  messageDeliveryImageUrl,
                  startAt,
                  endAt,
                  lastMessageDeliveryJobId,
                  ...summaryPerMessageDeliveryItem
                },
                i,
              ) => ({
                key: i.toString(),
                type: "messageDelivery",
                isNotMessageOpenCountCollected,
                name: messageDeliveryName,
                image: messageDeliveryImageUrl ?? null,
                startAt,
                endAt,
                lastMessageDeliveryJobId,
                ...summaryPerMessageDeliveryItem,
                children: summaryPerMessageDeliveryItem.items.map(
                  (summaryPerMessageDeliveryAndShopItem, ii) => ({
                    key: `${i}-${ii}`,
                    type: "shop",
                    isNotMessageOpenCountCollected,
                    name: summaryPerMessageDeliveryAndShopItem.shopName,
                    startAt,
                    endAt,
                    lastMessageDeliveryJobId,
                    image: null,
                    children: null,
                    ...summaryPerMessageDeliveryAndShopItem,
                  }),
                ),
              }),
            ),
      [isNotMessageOpenCountCollected, summaryPerMessageDelivery],
    );

    const handleChangePagination = useCallback(
      ({ position: _, ...pagination }: TablePaginationConfig) => setPagination(pagination),
      [setPagination],
    );

    const columns = useMemo(
      () => createColumns({ editable: canAccess("editMessageDelivery") }),
      [canAccess],
    );

    if (!summaryPerMessageDelivery) return <Loading height={400} />;

    return (
      <TableStyleModifier>
        <Table
          columns={columns}
          dataSource={dataSource}
          bordered
          pagination={pagination}
          onChange={handleChangePagination}
          expandable={expandable}
          scroll={{ x: 1136 }}
        />
      </TableStyleModifier>
    );
  },
);
