import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuRecommendationHeader } from "components/PageHeader/MenuRecommendationHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardAccountRoleTypeEnum } from "types/graphql";

import { AddMenuRecommendationMenuModal } from "./AddMenuRecommendationModal";
import { MenuTable } from "./MenuTable";
import {
  useMenuRecommendationMenusDeleteMenuRecommendationMutation,
  useMenuRecommendationMenusGetMenuRecommendationQuery,
  useMenuRecommendationMenusInsertCategoryMenusMutation,
} from "./queries";
import { CategoryMenu } from "./types";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const MenuRecommendationMenus = () => {
  const { menuRecommendationMetaId } = useParams<{
    menuRecommendationMetaId: string;
  }>();
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const {
    data: getMenuRecommendationData,
    loading: loadingMenuRecommendation,
    refetch: refetchMenuRecommendation,
    error,
  } = useMenuRecommendationMenusGetMenuRecommendationQuery(
    menuRecommendationMetaId ? { variables: { menuRecommendationMetaId } } : { skip: true },
  );
  const menuRecommendation = getMenuRecommendationData?.menuRecommendationMeta_by_pk;
  const categoryMenus = menuRecommendation?.category.categoryMenus ?? [];
  const menuRecommendationCategory = menuRecommendation?.category;

  const [deleteMenuRecommendationMutation, { loading: loadingUpdateMenuRecommendation }] =
    useMenuRecommendationMenusDeleteMenuRecommendationMutation();

  const [insertCategoryMenus] = useMenuRecommendationMenusInsertCategoryMenusMutation();

  const onRemove = useCallback(
    async (categoryMenuId: number) => {
      try {
        await deleteMenuRecommendationMutation({
          variables: { categoryMenuId },
        });
        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchMenuRecommendation();
    },
    [refetchMenuRecommendation, deleteMenuRecommendationMutation],
  );

  const onChangeOrder = useCallback(
    async (categoryMenus: CategoryMenu[]) => {
      if (!menuRecommendationCategory) return;

      const categoryMenusWithPriority = categoryMenus.map((categoryMenu, index) => ({
        serial: categoryMenu.categoryMenuId,
        menuId: categoryMenu.menu.id,
        _menuId: categoryMenu.menu.menuId,
        categoryId: menuRecommendationCategory.id,
        _categoryId: menuRecommendationCategory.categoryId,
        priority: index,
      }));

      try {
        await insertCategoryMenus({
          variables: { categoryMenus: categoryMenusWithPriority },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      await refetchMenuRecommendation();
    },
    [insertCategoryMenus, menuRecommendationCategory, refetchMenuRecommendation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);
  const goBackToMenuRecommendationMenu = useCallback(() => {
    navigate(`/menuRecommendation/${menuRecommendationMetaId}/menus`, { replace: true });
  }, [navigate, menuRecommendationMetaId]);

  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  return (
    <DashboardLayout
      title={menuRecommendation?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "おすすめ" }],
      }}
    >
      <MenuRecommendationHeader
        menuRecommendation={menuRecommendation ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      {loadingMenuRecommendation && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menuRecommendation && menuRecommendationCategory && (
        <FormContent>
          {isFeatureEnabled("editMenuRecommendation") && (
            <ButtonWrapper>
              <Link
                key="addCategoryMenu"
                to={`/menuRecommendation/${menuRecommendationMetaId}/menus/add`}
                replace
              >
                <Button type="primary">追加</Button>
              </Link>
            </ButtonWrapper>
          )}{" "}
          <MenuTable
            categoryMenus={categoryMenus}
            onRemove={onRemove}
            loading={loadingUpdateMenuRecommendation}
            menuRecommendationCategoryId={menuRecommendationCategory.categoryId}
            mutationDisabled={!isFeatureEnabled("editMenuRecommendation")}
            onChangeOrder={onChangeOrder}
          />
          <Routes>
            <Route
              path="add"
              element={
                <AddMenuRecommendationMenuModal
                  categoryMenus={categoryMenus}
                  menuRecommendationMetaId={menuRecommendation.id}
                  menuRecommendationCategory={menuRecommendationCategory}
                  goBack={goBackToMenuRecommendationMenu}
                  disabled={!isFeatureEnabled("editMenuRecommendation")}
                  refetchMenuRecommendation={refetchMenuRecommendation}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
