import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Radio, RadioChangeEvent } from "antd";
import { intersection } from "lodash";
import { filterMenusByName } from "models/menu";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { FilterConditions, MenuFilter } from "pages/Menus/MenuFilter";
import { MenuTable, MenuTableDisplayType } from "pages/Menus/MenuTable";
import {
  useMenusArchiveMenuMutation,
  useMenusGetCategoriesQuery,
  useMenusGetMenusQuery,
  useMenusGetShopsQuery,
} from "pages/Menus/queries";
import { Menu } from "pages/Menus/types";
import { CategoryMenu, DashboardAccountRoleTypeEnum, DisplayTypeEnum } from "types/graphql";

const isIncludedInDisplayTypes = (
  menu: { displayType: CategoryMenu["displayType"] },
  displayTypes: DisplayTypeEnum[],
) => displayTypes.includes(menu.displayType);

const displayTypeOptions: { label: string; value: MenuTableDisplayType }[] = [
  { label: "簡易表示", value: "compact" },
  { label: "詳細表示", value: "detail" },
];

const RadioGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const filterMenus = (
  menus: Menu[],
  { categoryIds, name, displayTypes, shopIds }: FilterConditions,
) => {
  const filteredMenus = menus.filter(
    (menu) =>
      (categoryIds === undefined ||
        menu.categoryMenus.some((categoryMenu) =>
          categoryIds.includes(categoryMenu.category.categoryId),
        )) &&
      (displayTypes === undefined ||
        menu.categoryMenus.some((categoryMenu) =>
          isIncludedInDisplayTypes(categoryMenu, displayTypes),
        )) &&
      (shopIds === undefined ||
        shopIds.length === 0 ||
        intersection(
          shopIds,
          menu.shopMenus.map(({ shopId }) => shopId),
        ).length > 0),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const Menus = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMenusData,
    loading: loadingMenus,
    refetch: refetchMenus,
  } = useMenusGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const menus: Menu[] = useMemo(() => getMenusData?.menu ?? [], [getMenusData]);

  const { data: getCategoriesData, error: getCategoriesDataError } = useMenusGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = useMemo(
    () => getCategoriesData?.category ?? [],
    [getCategoriesData?.category],
  );

  const { data: getShopsData, error: getShopsDataError } = useMenusGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);
  const shopIdToShopMap = useMemo(() => new Map(shops.map((shop) => [shop.shopId, shop])), [shops]);

  const [archiveMenuMutation] = useMenusArchiveMenuMutation();

  const onDelete = useCallback(
    async ({ menuId, serial }: { menuId: string; serial: number }) => {
      try {
        if (!companyId) return;

        const targetMenu = menus.find((menu) => menu.id === menuId);
        if (!targetMenu) throw new Error();

        await archiveMenuMutation({
          variables: {
            input: {
              companyId,
              menuId,
              _menuId: serial,
            },
          },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchMenus();
    },
    [archiveMenuMutation, companyId, menus, refetchMenus],
  );

  const { accessibleShopIds, role } = useCurrentUserPermissions();
  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({
      shopIds:
        role === DashboardAccountRoleTypeEnum.ShopMember
          ? accessibleShopIds?.filter((shopId) => shopIdToShopMap.has(shopId))
          : undefined,
    });

  const filteredMenus = useMemo(
    () => filterMenus(menus, filterConditions),
    [menus, filterConditions],
  );

  const [displayType, setDisplayType] = useState<MenuTableDisplayType>("compact");

  const onClickDisplayTypeButton = useCallback((event: RadioChangeEvent) => {
    setDisplayType(event.target.value);
  }, []);

  const shouldShowAlert = getCategoriesDataError || getShopsDataError;

  return (
    <DashboardLayout title="メニュー一覧">
      <PageHeader
        title="メニュー一覧"
        extra={[
          isFeatureEnabled("addMenu") && (
            <Link key="add" to="/menu/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
          isFeatureEnabled("editMenu") && (
            <Link key="editPriority" to="/menu/priority/edit">
              <Button>表示順編集</Button>
            </Link>
          ),
          isFeatureEnabled("editMenu") && (
            <Link key="editBulk" to="/menu/edit/bulk">
              <Button>一括設定</Button>
            </Link>
          ),
        ]}
        footer={
          <>
            <RadioGroupWrapper>
              <Radio.Group
                options={displayTypeOptions}
                onChange={onClickDisplayTypeButton}
                value={displayType}
                optionType="button"
              />
            </RadioGroupWrapper>
            <MenuFilter
              shops={shops}
              categories={categories}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <MenuTable
        displayType={displayType}
        shopIdToShopMap={shopIdToShopMap}
        menus={filteredMenus}
        loading={loadingMenus}
        onDelete={onDelete}
      />
    </DashboardLayout>
  );
};
