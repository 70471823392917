import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { MenuFilter } from "pages/EditMenuPriorities/MenuFilter";
import {
  useEditMenuPrioritiesGetCategoriesQuery,
  useEditMenuPrioritiesGetMenusQuery,
  useEditMenuPrioritiesInsertCategoryMenusMutation,
} from "pages/EditMenuPriorities/queries";
import { CategoryMenuInsertInput } from "types/graphql";

import { EditMenuPriorityList } from "./EditMenuPriorityList";

export type FilterConditions = {
  categoryIds?: number[];
};

export const EditMenuPriorities = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMenusData,
    loading: loadingMenus,
    refetch: refetchMenus,
  } = useEditMenuPrioritiesGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const category = useMemo(() => getMenusData?.category ?? [], [getMenusData]);
  const categoryMenus = useMemo(
    () => category.flatMap(({ categoryMenus }) => categoryMenus),
    [category],
  );

  const {
    data: getCategoriesData,
    loading: loadingCategories,
    error,
  } = useEditMenuPrioritiesGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const [categoryId, setCategoryId] = useState<string | null>(null);

  const [insertCategoryMenusMutation, { loading: loadingInsertingCategoryMenus }] =
    useEditMenuPrioritiesInsertCategoryMenusMutation();

  const onUpdate = useCallback(
    async (categoryMenus: CategoryMenuInsertInput[]) => {
      try {
        await insertCategoryMenusMutation({
          variables: { categoryMenus },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchMenus();
    },
    [refetchMenus, insertCategoryMenusMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const filteredCategoryMenus = useMemo(
    () => categoryMenus.filter((categoryMenu) => categoryMenu.categoryId === categoryId),
    [categoryMenus, categoryId],
  );

  return (
    <DashboardLayout
      title="メニューの表示順を編集"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      <PageHeader title="メニューの表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <MenuFilter categories={categories} categoryId={categoryId} setCategoryId={setCategoryId} />
        {categoryId !== null && (
          <EditMenuPriorityList
            loading={loadingMenus || loadingCategories || loadingInsertingCategoryMenus}
            categoryMenus={filteredCategoryMenus}
            onUpdate={onUpdate}
          />
        )}
      </FormContent>
    </DashboardLayout>
  );
};
